import NotificationModal from "components/notification/notificationModal/notificationModal";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useSelector } from "react-redux";
import { getImageUrl } from "utils";
import { Icons } from "utils/iconPath";
import useWindowDimensions from "utils/useWindowDimensions";
import "./topBar.css";

const TopBar = ({ onClick }) => {
  // Variables
  const customerBookingURL = process.env.REACT_APP_CUSTOMER_BOOKING_URL;

  // hooks
  const { width } = useWindowDimensions();
  const { t } = useTranslation("topBar");

  // states
  const [open, setOpen] = useState();
  // const [translationModalOpen, setTranslationModalOpen] = useState(false);

  const supplierProfileRes = useSelector(
    (state) => state.supplierProfile.supplierProfileRes
  );

  // functions
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const currentTime = new Date().getHours();
  let greeting = t("good_evening");

  if (currentTime >= 0 && currentTime < 12) {
    greeting = t("good_morning");
  } else if (currentTime >= 12 && currentTime < 18) {
    greeting = t("good_afternoon");
  }

  return (
    <>
      <div className="top_bar_container" data-aos="fade-down" id="topBar">
        <div className="user_detail_container">
          <img
            className="avatar"
            src={getImageUrl(supplierProfileRes?.logo) ?? Icons.AVATAR}
            alt="avatar"
          />
          <div>
            <p
              className="heading3"
              style={{ fontFamily: "AgeoBold", textTransform: "capitalize" }}
            >
              {greeting} {supplierProfileRes?.company_name ?? "Good Guys - Supplier"}
            </p>

            {supplierProfileRes?.slug ? (
              <a
                href={customerBookingURL + supplierProfileRes?.slug}
                target="_blank"
                rel="noreferrer"
                className="viewProfile bodyText"
                style={{ textTransform: "capitalize" }}
              >
                view profile
              </a>
            ) : null}
          </div>
        </div>
        <div className="iconsContainer" style={{ display: "flex" }}>
          <div className="notification_container">
            <div
              className={"notification_icon_container"}
              onClick={() => {
                setOpen(!open);
              }}
            >
              <img
                src={Icons.NOTIFICATION}
                alt="notification"
                className="notification_icon"
                style={{}}
              />
            </div>
            <div className="topBarIconContainer" onClick={onClick}>
              <img src={Icons.H_MENU} alt="menu" className="topBarIcon" />
            </div>
          </div>
        </div>
      </div>
      <Drawer
        open={open}
        onClose={toggleDrawer}
        direction="right"
        style={{
          width: width <= 768 ? "100vw" : "85rem",
        }}
        duration={1000}
        overlayOpacity={0.25}
      >
        <NotificationModal isOpen={open} setIsOpen={setOpen} />
      </Drawer>
    </>
  );
};

export default TopBar;
