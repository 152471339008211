import { Icons } from "utils/iconPath";
import "./style.css";

const CheckBox = ({
  label,
  value,
  onChange,
  style,
  name,
  id,
  disabled,
  required,
  className,
}) => {
  return (
    <label
      htmlFor={id}
      className={`bodyText checkBoxLabel ${className}`}
      aria-disabled={disabled}
      style={{
        ...style,
        opacity: disabled ? 0.35 : 1,
        cursor: disabled ? "not-allowed" : "pointer",
      }}
    >
      <img
        src={value ? Icons.CHECKED : Icons.UNCHECKED}
        alt={value ? "checked" : "unchecked"}
        className="CheckboxIcons clickable"
      />
      {label}
      <input
        type="checkbox"
        checked={value}
        onChange={onChange}
        className="checkBoxInput"
        id={id}
        name={name}
        required={required}
      />
    </label>
  );
};

export default CheckBox;
