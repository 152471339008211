import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { API_CUSTOMERS } from "redux/services/apiTypes";

const initialState = {
  customerListLoading: false,
  customerListSuccess: false,
  customerListRes: null,
  customerListErr: null,
};

export const getCustomerList = createAsyncThunk(
  "customer/list",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${API_CUSTOMERS}${payload}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const customerListSlice = createSlice({
  name: "customerList",
  initialState,
  reducers: {
    clearCustomerList: (state) => {
      state.customerListLoading = false;
      state.customerListSuccess = false;
      state.customerListRes = null;
      state.customerListErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerList.pending, (state) => {
        state.customerListLoading = true;
        state.customerListSuccess = false;
      })
      .addCase(getCustomerList.fulfilled, (state, action) => {
        state.customerListLoading = false;
        state.customerListSuccess = true;
        state.customerListRes = action.payload;
      })
      .addCase(getCustomerList.rejected, (state, action) => {
        state.customerListLoading = false;
        state.customerListSuccess = true;
        state.customerListErr = action.payload;
      });
  },
});

export const { clearCustomerList } = customerListSlice.actions;

export default customerListSlice.reducer;
