import Modal from "common/Modal";
import "./style.css";
import Input from "common/Input";
import { SecondaryButton } from "common/Button";
import { useDeferredValue, useEffect, useState } from "react";

const EditPriceModal = ({ isOpen, onClose, setEditedPrice, editedPrice }) => {
  const [localPrice, setLocalPrice] = useState("");

  useEffect(() => {
    if (editedPrice || Number(editedPrice) === 0) {
      setLocalPrice(editedPrice);
    }
  }, [editedPrice, isOpen]);

  return (
    <Modal
      onClose={() => {
        onClose();
        setLocalPrice("");
      }}
      isOpen={isOpen}
      modalHeading={"Edit Price"}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (setEditedPrice) setEditedPrice(localPrice);
          onClose();
        }}
        className="editPriceFrom"
      >
        <Input
          type={"number"}
          placeholder={"Enter your price"}
          onChange={(e) => {
            setLocalPrice(e.target.value);
          }}
          value={localPrice}
        />
        <SecondaryButton title={"Submit"} type={"submit"} />
      </form>
    </Modal>
  );
};

export default EditPriceModal;
