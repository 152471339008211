import Modal from "common/Modal";
import { SecondaryButton, TertiaryButton } from "common/Button";
import { getErrorMessage, makeUserLogout } from "utils";
import { useTranslation } from "react-i18next";
import "./style.css";
import { API_USERS } from "redux/services/apiTypes";
import toast from "react-hot-toast";
import defaultFunction from "redux/services/apiCall";

const LogoutModal = ({ isOpen, onClose }) => {
  const { t } = useTranslation("sideBar");

  async function handleLogout(conditionString) {
    const apiData = {
      type: `${API_USERS}${conditionString}`,
      apiType: "POST",
    };
    try {
      await defaultFunction(apiData);
      makeUserLogout();
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      modalHeading={t("logout_confirmation")}
    >
      <div className="modalChildrenContainer">
        <div className="heading3 modalChildrenHeading">
          {t("are_you_sure_you_want_to_log_out")}
        </div>
        <div className="modalChildrenButtonContainerLogout">
          <SecondaryButton
            title={t("logout_from_this_device")}
            onClick={() => handleLogout("sign-out/")}
            style={{ backgroundColor: "#FF4D4F", color: "white" }}
          />
          <SecondaryButton
            title={t("logout_from_all_devices")}
            onClick={() => handleLogout("sign-out-all/")}
            style={{ backgroundColor: "#FF4D4F15", color: "var(--error)" }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default LogoutModal;
