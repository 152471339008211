import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { SUPPLIER_PROFILE } from "redux/services/apiTypes";

const initialState = {
  supplierProfileLoading: false,
  supplierProfileSuccess: false,
  supplierProfileRes: null,
  supplierProfileErr: null,
};

export const getSupplierProfile = createAsyncThunk(
  "supplier/profile",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: SUPPLIER_PROFILE,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const supplierProfileSlice = createSlice({
  name: "supplierProfile",
  initialState,
  reducers: {
    clearSupplierProfile: (state, action) => {
      state.supplierProfileLoading = false;
      state.supplierProfileSuccess = false;
      state.supplierProfileRes = null;
      state.supplierProfileErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSupplierProfile.pending, (state, action) => {
        state.supplierProfileLoading = true;
        state.supplierProfileSuccess = false;
      })
      .addCase(getSupplierProfile.fulfilled, (state, action) => {
        state.supplierProfileLoading = false;
        state.supplierProfileSuccess = true;
        state.supplierProfileRes = action.payload;
      })
      .addCase(getSupplierProfile.rejected, (state, action) => {
        state.supplierProfileLoading = false;
        state.supplierProfileSuccess = false;
        state.supplierProfileErr = action.payload;
      });
  },
});

export const { clearSupplierProfile } = supplierProfileSlice.actions;

export default supplierProfileSlice.reducer;
