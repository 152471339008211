import "./style.css";
import Input, { TextArea } from "common/Input";
import CustomDropDown from "common/dropdown";
import { RadioButton } from "common/radioButton";
import SwitchButton from "common/switchButton/SwitchButton";
import { SERVICE_FIELD_TYPE } from "utils/constant";
import { Icons } from "utils/iconPath";

export const DynamicFields = ({
  data,
  extraInfoData,
  onChangeDynamicFields,
}) => {
  let fieldType = data?.field_type;

  const fieldValue =
    (extraInfoData[data?.field_name] &&
      extraInfoData[data?.field_name]?.field_value) ||
    0;

  const onChangeIntegerValue = (type) => {
    let currentValue = fieldValue || 0;

    if (type === "minus") {
      if (currentValue <= 0) {
        return;
      }
      currentValue -= 1;
    } else {
      currentValue += 1;
    }
    onChangeDynamicFields(data, currentValue);
  };

  return (
    <>
      {data?.is_active ? (
        <div className="DynamicRenderFieldContainer">
          {SERVICE_FIELD_TYPE?.TEXT === fieldType && data?.is_active ? (
            <span>
              <TextArea
                label={data?.label}
                rows={5}
                placeholder={data?.label}
                onChange={(e) => {
                  onChangeDynamicFields(data, e.target.value);
                }}
                value={fieldValue || ""}
                isRequired={data?.is_required}
              />
            </span>
          ) : null}

          {SERVICE_FIELD_TYPE?.INTEGER === fieldType && data?.is_active ? (
            <span>
              <div className="serviceCounterContainer">
                <div className="bodyText serviceFieldHeading">
                  {data?.label + ""}
                  {!data?.is_required ? (
                    <span className="bodyText optionalText">Optional </span>
                  ) : null}
                </div>
                <div className="counterContainer">
                  <div className="counterButtonContainer">
                    <img
                      src={Icons?.SQUARE_MINUS}
                      alt="decrement"
                      className={`counterButton ${
                        fieldValue === 0 ? "counterButton_disabled" : ""
                      }`}
                      onClick={() => {
                        onChangeIntegerValue("minus");
                      }}
                      style={{
                        opacity: fieldValue === 0 ? 0.25 : 1,
                      }}
                    />
                  </div>
                  <p className="counterNumber">{fieldValue}</p>
                  <div className="counterButtonContainer">
                    <img
                      src={Icons?.SQUARE_PLUS}
                      alt="decrement"
                      className="counterButton"
                      onClick={() => {
                        onChangeIntegerValue("plus");
                      }}
                    />
                  </div>
                </div>
              </div>
            </span>
          ) : null}
          {SERVICE_FIELD_TYPE?.CHARACTER === fieldType && data?.is_active ? (
            <span>
              <Input
                label={data?.label}
                placeholder={data?.label}
                inputStyle={{
                  width: "100%",
                }}
                onChange={(e) => {
                  onChangeDynamicFields(data, e.target.value);
                }}
                value={fieldValue || ""}
                isRequired={data?.is_required}
              />
            </span>
          ) : null}
          {SERVICE_FIELD_TYPE?.OPTIONS === fieldType && data?.is_active ? (
            <span>
              <div className="priceCombinationContainer">
                <p
                  className="bodyText serviceFieldHeading "
                  style={{ opacity: 1 }}
                >
                  {data?.label}{" "}
                  {!data?.is_required ? (
                    <span className="bodyText optionalText">Optional </span>
                  ) : null}
                </p>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1.5rem",
                  }}
                >
                  {Object.keys(data.object_value).map((item, index) => {
                    let radioValue;
                    if (item === fieldValue) {
                      radioValue = true;
                    } else {
                      radioValue = false;
                    }

                    return (
                      <RadioButton
                        label={item}
                        key={index}
                        onChange={() => {
                          onChangeDynamicFields(data, item);
                        }}
                        checked={radioValue || false}
                        isRequired={data?.is_required}
                      />
                    );
                  })}
                </div>
              </div>
            </span>
          ) : null}

          {SERVICE_FIELD_TYPE?.PRICE_COMBINATION === fieldType &&
          data?.is_active ? (
            <span>
              {Object.keys(data.object_value).map((key) => ({
                key: key,
                value: data.object_value[key],
              })).length > 4 ? (
                <CustomDropDown
                  dropDownData={Object.keys(data.object_value).map((key) => ({
                    key: key,
                    value: data.object_value[key],
                  }))}
                  setSelectedValue={(e) => {
                    onChangeDynamicFields(data, e);
                  }}
                  selectedValue={fieldValue || ""}
                  displayKey={"key"}
                  valueKey={"key"}
                  placeholder={""}
                  label={data?.label}
                  dynamicValue={fieldValue}
                  styleMenuContainer={{
                    width: "100%",
                  }}
                  isRequired={data?.is_required}
                />
              ) : (
                <div className="priceCombinationContainer">
                  <p
                    className="bodyText serviceFieldHeading"
                    style={{ opacity: 1, marginBottom: "1.5rem" }}
                  >
                    {data.label}
                    {!data?.is_required ? (
                      <p
                        className="bodyText optionalText"
                        style={{ display: "inline", marginLeft: "2rem" }}
                      >
                        Optional
                      </p>
                    ) : null}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "1.5rem",
                    }}
                  >
                    {Object.keys(data.object_value).map((item, index) => {
                      let radioValue;
                      if (item === fieldValue) {
                        radioValue = true;
                      } else {
                        radioValue = false;
                      }

                      return (
                        <RadioButton
                          label={item}
                          key={index}
                          onChange={() => {
                            onChangeDynamicFields(data, item);
                          }}
                          checked={radioValue || false}
                          isRequired={data?.is_required}
                        />
                      );
                    })}
                  </div>
                </div>
              )}
            </span>
          ) : null}
          {SERVICE_FIELD_TYPE?.DROPDOWN === fieldType && data?.is_active ? (
            <span>
              <CustomDropDown
                dropDownData={Object.keys(data.object_value).map((key) => ({
                  key: key,
                  value: data.object_value[key],
                }))}
                setSelectedValue={(e) => {
                  onChangeDynamicFields(data, e);
                }}
                selectedValue={fieldValue || ""}
                displayKey={"key"}
                valueKey={"key"}
                placeholder={""}
                label={data.label}
                dynamicValue={fieldValue || ""}
                styleMenuContainer={{
                  width: "100%",
                }}
                isRequired={data?.is_required}
              />
            </span>
          ) : null}

          {SERVICE_FIELD_TYPE?.BOOLEAN === fieldType && data?.is_active ? (
            <span>
              <div className="serviceCounterContainer">
                <p className="bodyText serviceFieldHeading">
                  {data.label}{" "}
                  {!data?.is_required ? (
                    <span className="bodyText optionalText">Optional</span>
                  ) : null}
                </p>
                <SwitchButton
                  onChange={(e) => {
                    onChangeDynamicFields(data, e.target.checked ? 1 : 0);
                  }}
                  value={Boolean(fieldValue) || false}
                  isRequired={data?.is_required}
                />
              </div>
            </span>
          ) : null}

          {SERVICE_FIELD_TYPE?.SECTION === fieldType && data?.is_active ? (
            <>
              <div className="sectionSeparatorDiv" />
              <span className="heading3 sectionSeparator">{data?.label}</span>
            </>
          ) : null}
        </div>
      ) : null}
    </>
  );
};
