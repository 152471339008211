import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { NEW_BOOKING } from "redux/services/apiTypes";

const initialState = {
  historyBookingLoading: false,
  historyBookingSuccess: false,
  historyBookingRes: null,
  historyBookingErr: null,
};

export const getHistoryBookings = createAsyncThunk(
  "bookings/history",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}${payload.query}`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreHistoryBookings = createAsyncThunk(
  "bookings/MoreHistory",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}${payload.query}`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const historyBookingSlice = createSlice({
  name: "historyBooking",
  initialState,
  reducers: {
    clearHistoryBooking: (state, action) => {
      state.historyBookingLoading = false;
      state.historyBookingSuccess = false;
      state.historyBookingRes = null;
      state.historyBookingErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHistoryBookings.pending, (state, action) => {
        state.historyBookingLoading = true;
        state.historyBookingSuccess = false;
      })
      .addCase(getHistoryBookings.fulfilled, (state, action) => {
        state.historyBookingLoading = false;
        state.historyBookingSuccess = true;
        state.historyBookingRes = action.payload;
      })
      .addCase(getHistoryBookings.rejected, (state, action) => {
        state.historyBookingLoading = false;
        state.historyBookingSuccess = true;
        state.historyBookingErr = action.payload;
      })
      .addCase(getMoreHistoryBookings.pending, (state, action) => {
        state.historyBookingLoading = false;
        state.historyBookingSuccess = false;
      })
      .addCase(getMoreHistoryBookings.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [
            ...state.historyBookingRes.results,
            ...action.payload.results,
          ],
        };
        state.historyBookingLoading = false;
        state.historyBookingSuccess = true;
        state.historyBookingRes = res;
      })
      .addCase(getMoreHistoryBookings.rejected, (state, action) => {
        state.historyBookingLoading = false;
        state.historyBookingSuccess = true;
        state.historyBookingErr = action.payload;
      });
  },
});

export const { clearHistoryBooking } = historyBookingSlice.actions;

export default historyBookingSlice.reducer;
