import { Icons } from "./iconPath";

export const USER_TOKEN = "token";
export const IS_KEEP_ME_LOGGED_IN = "isKeepMeLoggedIn";

export const SIDEBAR_WIDTH = "40rem";
export const CURRENCY = "LKR";

export const LANGUAGE_TYPE = {
  en: "English",
  ar: "Arabic",
};

export const roles = {
  is_service_provider: "is_service_provider",
  is_staff: "is_staff",
};

export const LANGUAGE_TYPE_OPTIONS = [
  { display: LANGUAGE_TYPE.en, key: "en" },
  { display: LANGUAGE_TYPE.ar, key: "ar" },
];

export const SETTING_TYPE = {
  myAccount: "my-account",
  myOrders: "my-orders",
  savedAddress: "saved-address",
  savedCards: "saved-cards",
  passwordSecurity: "password-security",
  platformSettings: "platform-settings",
};

export const SETTING_OPTIONS = [
  { display: SETTING_TYPE.myAccount, key: SETTING_TYPE.myAccount },
  { display: SETTING_TYPE.myOrders, key: SETTING_TYPE.myOrders },
  { display: SETTING_TYPE.savedAddress, key: SETTING_TYPE.savedAddress },
  { display: SETTING_TYPE.savedCards, key: SETTING_TYPE.savedCards },
  {
    display: SETTING_TYPE.passwordSecurity,
    key: SETTING_TYPE.passwordSecurity,
  },
  {
    display: SETTING_TYPE.platformSettings,
    key: SETTING_TYPE.platformSettings,
  },
];

export const GENDER_OPTIONS = [
  { display: "male", key: "male" },
  { display: "female", key: "female" },
  { display: "others", key: "others" },
];

//LISTING CONSTANTS
export const GET_LIST = "getList";
export const GET_PAGINATION_LIST = "getPaginationList";
export const PAGINATION_INITIAL_LIST_RES = {
  count: null,
  next: null,
  previous: null,
  results: [],
};

export const TIME_ARRAY = [
  { value: "00:00:00", displayName: "12:00 AM" },
  { value: "00:30:00", displayName: "12:30 AM" },
  { value: "01:00:00", displayName: "01:00 AM" },
  { value: "01:30:00", displayName: "01:30 AM" },
  { value: "02:00:00", displayName: "02:00 AM" },
  { value: "02:30:00", displayName: "02:30 AM" },
  { value: "03:00:00", displayName: "03:00 AM" },
  { value: "03:30:00", displayName: "03:30 AM" },
  { value: "04:00:00", displayName: "04:00 AM" },
  { value: "04:30:00", displayName: "04:30 AM" },
  { value: "05:00:00", displayName: "05:00 AM" },
  { value: "05:30:00", displayName: "05:30 AM" },
  { value: "06:00:00", displayName: "06:00 AM" },
  { value: "06:30:00", displayName: "06:30 AM" },
  { value: "07:00:00", displayName: "07:00 AM" },
  { value: "07:30:00", displayName: "07:30 AM" },
  { value: "08:00:00", displayName: "08:00 AM" },
  { value: "08:30:00", displayName: "08:30 AM" },
  { value: "09:00:00", displayName: "09:00 AM" },
  { value: "09:30:00", displayName: "09:30 AM" },
  { value: "10:00:00", displayName: "10:00 AM" },
  { value: "10:30:00", displayName: "10:30 AM" },
  { value: "11:00:00", displayName: "11:00 AM" },
  { value: "11:30:00", displayName: "11:30 AM" },
  { value: "12:00:00", displayName: "12:00 PM" },
  { value: "12:30:00", displayName: "12:30 PM" },
  { value: "13:00:00", displayName: "01:00 PM" },
  { value: "13:30:00", displayName: "01:30 PM" },
  { value: "14:00:00", displayName: "02:00 PM" },
  { value: "14:30:00", displayName: "02:30 PM" },
  { value: "15:00:00", displayName: "03:00 PM" },
  { value: "15:30:00", displayName: "03:30 PM" },
  { value: "16:00:00", displayName: "04:00 PM" },
  { value: "16:30:00", displayName: "04:30 PM" },
  { value: "17:00:00", displayName: "05:00 PM" },
  { value: "17:30:00", displayName: "05:30 PM" },
  { value: "18:00:00", displayName: "06:00 PM" },
  { value: "18:30:00", displayName: "06:30 PM" },
  { value: "19:00:00", displayName: "07:00 PM" },
  { value: "19:30:00", displayName: "07:30 PM" },
  { value: "20:00:00", displayName: "08:00 PM" },
  { value: "20:30:00", displayName: "08:30 PM" },
  { value: "21:00:00", displayName: "09:00 PM" },
  { value: "21:30:00", displayName: "09:30 PM" },
  { value: "22:00:00", displayName: "10:00 PM" },
  { value: "22:30:00", displayName: "10:30 PM" },
  { value: "23:00:00", displayName: "11:00 PM" },
  { value: "23:30:00", displayName: "11:30 PM" },
];

export const ADDITIONAL_PAYMENT_STATUS = {
  ACCEPTED: "accepted",
  REJECTED: "rejected",
  PENDING: "pending",
};

export const HOME_BOOKING_CHART_FILTER_TYPES = [
  { value: "today", displayName: "Today" },
  { value: "yesterday", displayName: "Yesterday" },
  { value: "this_week", displayName: "This_week" },
  { value: "last_week", displayName: "Last_week" },
  { value: "this_month", displayName: "This_month" },
  { value: "last_month", displayName: "Last_month" },
  { value: "this_year", displayName: "This_year" },
  { value: "last_year", displayName: "Last_year" },
  { value: "life_time", displayName: "Life_time" },
];

export const HOME_REVENUE_CHART_FILTER_TYPES = [
  { value: "this_month", displayName: "This_month" },
  { value: "last_month", displayName: "Last_month" },
  { value: "this_year", displayName: "This_year" },
  { value: "last_year", displayName: "Last_year" },
  { value: "life_time", displayName: "Life_time" },
];

export const TRANSLATION = [
  { value: "en", displayName: "English International" },
  { value: "tam", displayName: "Tamil" },
  { value: "sin", displayName: "Sinhala " },
];

export const SERVICE_FIELD_TYPE = {
  INTEGER: "IntegerField",
  TEXT: "TextField",
  CHARACTER: "CharField",
  BOOLEAN: "BooleanField",
  OPTIONS: "OptionField",
  DROPDOWN: "DropDownField",
  PRICE_COMBINATION: "PriceCombinationField",
  SECTION: "SectionField",
};

export const SERVICE_FIELD_TYPE_OPTIONS = [
  {
    display: "Integer (Plus Minus button)",
    key: SERVICE_FIELD_TYPE.INTEGER,
  },
  {
    display: "Text Field (Description box)",
    key: SERVICE_FIELD_TYPE.TEXT,
  },
  {
    display: "Char Field (Single line text box)",
    key: SERVICE_FIELD_TYPE.CHARACTER,
  },
  {
    display: "Boolean Field (On Off switch)",
    key: SERVICE_FIELD_TYPE.BOOLEAN,
  },
  {
    display: "Option Field (Options without price calculations)",
    key: SERVICE_FIELD_TYPE.OPTIONS,
  },
  {
    display: "Price Combination Field (Options with price calculations)",
    key: SERVICE_FIELD_TYPE.PRICE_COMBINATION,
  },
  {
    display: "Drop Down Field (Dropdown)",
    key: SERVICE_FIELD_TYPE.DROPDOWN,
  },
  {
    display: "Section Filed (Separator)",
    key: SERVICE_FIELD_TYPE.SECTION,
  },
];

export const NOTIFICATION_TYPE = {
  SERVICE: "services",
  SYSTEM: "system",
  BOOKING: "booking",
  NEW_BOOKING: "new_booking",
  PROFILE: "profile",
  PROMO: "promo",
  WALLET: "wallet",
  RECURRING_BOOKING: "recurring_booking",
};

export const SERVICE_STATUS = {
  CREATED: "created",
  ACCEPTED: "accepted",
  IN_TRANSIT: "in_transit",
  AT_DESTINATION: "at_destination",
  IN_PROGRESS: "in_progress",
  COMPLETED: "completed",
  CANCELLED: "cancelled",
  CANCELLED_DUE_PAYMENT: "cancelled_due_payment",
  CANCELLED_BY_SUPPLIER: "cancelled_by_supplier",
  CANCELLED_BY_CLIENT: "cancelled_by_client",
};
export const GOOGLE_MAP_BASE_URL = "https://maps.googleapis.com/maps/api/";
export const MAP_ADDRES_FROM_LATLONG =
  GOOGLE_MAP_BASE_URL + "geocode/json?latlng=";

export const MAP_ADDRESS_FROM_TEXT =
  GOOGLE_MAP_BASE_URL + "place/textsearch/json?query=";

export const GOOGLE_MAP_API_KEY = "AIzaSyAvjKwFLs8q7GiEZvqRoxbucvVtSBy_7UI";

export const RENDER_CUSTOMER = [
  {
    id: 1,
    name: "John Doe",
    city: "New York",
    sector: "IT",
    position: "Software Engineer",
    mobileNumber: "123-456-7890",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 2,
    name: "Jane Smith",
    city: "Los Angeles",
    sector: "Finance",
    position: "Accountant",
    mobileNumber: "987-654-3210",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 3,
    name: "Bob Johnson",
    city: "Chicago",
    sector: "Marketing",
    position: "Marketing Specialist",
    mobileNumber: "555-123-4567",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 4,
    name: "Alice Brown",
    city: "San Francisco",
    sector: "Technology",
    position: "Systems Analyst",
    mobileNumber: "789-012-3456",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 5,
    name: "Charlie Wilson",
    city: "Seattle",
    sector: "Engineering",
    position: "Mechanical Engineer",
    mobileNumber: "321-654-9870",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 6,
    name: "Eva Miller",
    city: "Boston",
    sector: "Healthcare",
    position: "Nurse",
    mobileNumber: "111-222-3333",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 7,
    name: "Frank Davis",
    city: "Denver",
    sector: "Education",
    position: "Teacher",
    mobileNumber: "444-555-6666",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 8,
    name: "Grace Turner",
    city: "Houston",
    sector: "Finance",
    position: "Financial Analyst",
    mobileNumber: "777-888-9999",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 9,
    name: "Henry White",
    city: "Miami",
    sector: "IT",
    position: "Database Administrator",
    mobileNumber: "666-555-4444",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 10,
    name: "Ivy Reed",
    city: "Phoenix",
    sector: "Marketing",
    position: "Content Creator",
    mobileNumber: "333-222-1111",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 11,
    name: "Jackie Turner",
    city: "Atlanta",
    sector: "Engineering",
    position: "Civil Engineer",
    mobileNumber: "999-888-7777",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 12,
    name: "Ken Lewis",
    city: "Dallas",
    sector: "Technology",
    position: "Software Developer",
    mobileNumber: "444-333-2222",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 13,
    name: "Lisa Anderson",
    city: "Chicago",
    sector: "Healthcare",
    position: "Doctor",
    mobileNumber: "555-666-7777",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 14,
    name: "Mike Robinson",
    city: "San Diego",
    sector: "Education",
    position: "Professor",
    mobileNumber: "888-999-0000",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 15,
    name: "Nancy Baker",
    city: "Portland",
    sector: "IT",
    position: "Network Administrator",
    mobileNumber: "222-111-3333",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 16,
    name: "Oscar Clark",
    city: "Seattle",
    sector: "Engineering",
    position: "Electrical Engineer",
    mobileNumber: "444-666-9999",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 17,
    name: "Pamela Hall",
    city: "San Francisco",
    sector: "Technology",
    position: "UX Designer",
    mobileNumber: "111-555-7777",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 18,
    name: "Quincy Ford",
    city: "New York",
    sector: "Finance",
    position: "Financial Planner",
    mobileNumber: "888-222-4444",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 19,
    name: "Rachel Reed",
    city: "Los Angeles",
    sector: "Marketing",
    position: "Social Media Manager",
    mobileNumber: "555-999-6666",
    email: "johndoe@gmail.com",
    booking: "20",
  },
  {
    id: 20,
    name: "Samuel Turner",
    city: "Houston",
    sector: "Healthcare",
    position: "Nurse Practitioner",
    mobileNumber: "777-333-1111",
    email: "johndoe@gmail.com",
    booking: "20",
  },
];

export const SOCIAL_HANDLES = [{
  platform: "instagram",
  link: "https://www.instagram.com/goodguysapp",
  icon: Icons?.INSTAGRAM
},
{
  platform: "linkedin",
  link: "https://www.linkedin.com/company/goodguysapp",
  icon: Icons?.LINKEDIN
}, {
  platform: "facebook",
  link: "https://web.facebook.com/goodguysapp",
  icon: Icons?.FACEBOOK
},
{
  platform: "twitter",
  link: "https://x.com/goodguysapp",
  icon: Icons?.TWITTER
}];