import CircularProgress from "@mui/material/CircularProgress";
import "./style.css";

export const CButton = ({
  title,
  onClick,
  style,
  type,
  startIcon,
  isLoading,
  iconPath,
  endIcon,
  startIconStyle,
  endIconStyle,
  disabled,
  loadingColor,
  buttonContainerStyle,
}) => {
  return (
    <div
      style={{ position: "relative", ...buttonContainerStyle }}
      aria-disabled={true}
    >
      <button
        className="CustomButton"
        onClick={onClick}
        type={type ? type : "submit"}
        disabled={disabled}
        style={{ ...style }}
      >
        {startIcon && (
          <img src={iconPath} alt="icons" style={{ ...startIconStyle }} />
        )}
        {title}
        {isLoading && (
          <CircularProgress
            size={20}
            sx={{
              color: loadingColor,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "auto",
            }}
          />
        )}
        {endIcon && (
          <img src={iconPath} alt="icons" style={{ ...endIconStyle }} />
        )}
      </button>
    </div>
  );
};

export const SecondaryButton = ({
  title,
  onClick,
  style,
  type,
  startIcon,
  isLoading,
  iconPath,
  endIcon,
  startIconStyle,
  endIconStyle,
  disabled,
  buttonClassName,
  otherTitle,
  loadingColor,
  Style,
}) => {
  return (
    <div
      style={{
        position: "relative",
        ...Style,
        cursor: disabled ? "not-allowed" : "pointer",
        opacity: disabled ? "0.3" : "1",
      }}
      className={buttonClassName}
      aria-disabled={true}
    >
      <button
        className={`SecondaryCustomButton`}
        onClick={onClick}
        type={type || "submit"}
        style={{
          ...style,
          cursor: disabled ? "not-allowed" : "pointer",
        }}
      >
        {startIcon && (
          <img src={iconPath} alt="icons" style={{ ...startIconStyle }} />
        )}
        {title} {otherTitle}
        {isLoading && (
          <CircularProgress
            size={20}
            sx={{
              color: loadingColor,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "auto",
            }}
          />
        )}
        {endIcon && (
          <img src={iconPath} alt="icons" style={{ ...endIconStyle }} />
        )}
      </button>
    </div>
  );
};

export const TertiaryButton = ({
  title,
  onClick,
  style,
  type,
  startIcon,
  isLoading,
  iconPath,
  endIcon,
  startIconStyle,
  endIconStyle,
  disabled,
  loadingColor,
}) => {
  return (
    <div style={{ position: "relative" }} aria-disabled={true}>
      <button
        className="TertiaryButton"
        onClick={onClick}
        type={type ? type : "submit"}
        disabled={disabled}
        style={{ ...style }}
      >
        {startIcon && (
          <img src={iconPath} alt="icons" style={{ ...startIconStyle }} />
        )}
        {title}
        {isLoading && (
          <CircularProgress
            size={20}
            sx={{
              color: loadingColor,
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              margin: "auto",
            }}
          />
        )}
        {endIcon && (
          <img src={iconPath} alt="icons" style={{ ...endIconStyle }} />
        )}
      </button>
    </div>
  );
};

export const IconButton = ({
  title,
  onClick,
  style,
  type,
  startIcon,
  isLoading,
  iconPath,
  endIcon,
  startIconStyle,
  endIconStyle,
  disabled,
}) => {
  return (
    <div style={{ position: "relative" }} aria-disabled={true}>
      <button
        className="IconButton"
        onClick={onClick}
        type={type ? type : "submit"}
        disabled={disabled}
        style={{ ...style }}
      >
        {startIcon && (
          <img src={iconPath} alt="icons" style={{ ...startIconStyle }} />
        )}
        {title}
        {isLoading && (
          <CircularProgress
            size={24}
            sx={{
              color: "white",
              position: "absolute",
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              margin: "auto",
            }}
          />
        )}
        {endIcon && (
          <img src={iconPath} alt="icons" style={{ ...endIconStyle }} />
        )}
      </button>
    </div>
  );
};
