export const API_V1 = process.env.REACT_APP_BASE_URL + "/api/v1/";

export const API_USERS = API_V1 + "users/";
export const SEND_OTP = API_USERS + "send-otp/";
export const RESEND_OTP = API_USERS + "resend-otp/";
export const VERIFY_OTP = API_USERS + "otp-verify/";
export const SUPPLIER_PROFILE = API_V1 + "service-provider/profile/";
export const CREATE_SUPPLIER = API_V1 + "service-provider/";
export const NEW_BOOKING = API_V1 + "service-request/";
export const NOTIFICATION = API_V1 + "notification/";
export const FAQ = API_V1 + "faq/";
export const AVAILABILITY = CREATE_SUPPLIER + "availability/";
export const TRANSACTION = CREATE_SUPPLIER + "earning-transactions/";
export const API_SERVICE_PROVIDER_DASHBOARD = CREATE_SUPPLIER + "dashboard/";
export const PAYBACK = CREATE_SUPPLIER + "payback-request/";
export const STAFF_AVAILABILITY = CREATE_SUPPLIER + "staff/";
export const API_SERVICE_PROVIDER_TOGGLE_MULTI_BOOKING =
  SUPPLIER_PROFILE + "allow_multiple_booking/";
export const API_FILE_UPLOAD = API_V1 + "service-request/";
export const MY_SERVICES = CREATE_SUPPLIER + "services/";
export const SINGLE_SERVICE = API_V1 + "service/";
export const MY_RATING = API_V1 + "service-rating/";
export const API_SERVICES = API_V1 + "service/";
export const API_CUSTOMER_VALIDATION = API_USERS + "customer/";
export const API_CUSTOMERS = CREATE_SUPPLIER + "customers/";
