const { Icons } = require("utils/iconPath");

const SideBarLinks = [
  {
    name: "Home",
    label: "home",
    link: "/home",
    icon: Icons.HOME,
  },
  {
    name: "My Calendar",
    label: "my_calendar",
    link: "/calendar",
    icon: Icons.CALENDAR_ACTIVE,
  },
  {
    name: "Bookings",
    label: "bookings",
    link: "/bookings",
    icon: Icons.BOOKING,
  },
  {
    name: "customer",
    label: "customers",
    link: "/customer",
    icon: Icons.CUSTOMER,
  },
  {
    name: "my-service",
    label: "my service",
    link: "/service",
    icon: Icons.MY_SERVICE_TAB,
  },
  {
    name: "Staff",
    label: "staff",
    link: "/staff",
    icon: Icons.STAFF,
  },
  {
    name: "earning",
    label: "earnings",
    link: "/earning",
    icon: Icons.EARNING_TAB,
  },
  {
    name: "Profile",
    label: "profile",
    link: "/profile",
    icon: Icons.PROFILE,
  },
];

export default SideBarLinks;
