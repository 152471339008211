import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { NEW_BOOKING } from "redux/services/apiTypes";

const initialState = {
  onGoingBookingLoading: false,
  onGoingBookingSuccess: false,
  onGoingBookingRes: null,
  onGoingBookingErr: null,
};

export const getOnGoingBookings = createAsyncThunk(
  "bookings/onGoing",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}${payload.query}`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreOnGoingBookings = createAsyncThunk(
  "bookings/MoreOnGoing",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}${payload.query}`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const onGoingBookingSlice = createSlice({
  name: "onGoingBooking",
  initialState,
  reducers: {
    clearOnGoingBooking: (state, action) => {
      state.onGoingBookingLoading = false;
      state.onGoingBookingSuccess = false;
      state.onGoingBookingRes = null;
      state.onGoingBookingErr = null;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(getOnGoingBookings.pending, (state, action) => {
        state.onGoingBookingLoading = true;
        state.onGoingBookingSuccess = false;
      })
      .addCase(getOnGoingBookings.fulfilled, (state, action) => {
        state.onGoingBookingLoading = false;
        state.onGoingBookingSuccess = true;
        state.onGoingBookingRes = action.payload;
      })
      .addCase(getOnGoingBookings.rejected, (state, action) => {
        state.onGoingBookingLoading = false;
        state.onGoingBookingSuccess = true;
        state.onGoingBookingErr = action.payload;
      })
      .addCase(getMoreOnGoingBookings.pending, (state, action) => {
        state.onGoingBookingLoading = false;
        state.onGoingBookingSuccess = false;
      })
      .addCase(getMoreOnGoingBookings.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [
            ...state.onGoingBookingRes.results,
            ...action.payload.results,
          ],
        };
        state.onGoingBookingLoading = false;
        state.onGoingBookingSuccess = true;
        state.onGoingBookingRes = res;
      })
      .addCase(getMoreOnGoingBookings.rejected, (state, action) => {
        state.onGoingBookingLoading = false;
        state.onGoingBookingSuccess = true;
        state.onGoingBookingErr = action.payload;
      });
  },
});

export const { clearOnGoingBooking } = onGoingBookingSlice.actions;

export default onGoingBookingSlice.reducer;
