import { useState } from "react";
import "./NotificationCard.css";
import { Icons } from "utils/iconPath";
import moment from "moment";

const NotificationCard = ({
  from,
  heading,
  message,
  is_seen,
  time,
  containerRef,
  id,
  onClick,
}) => {
  const [read, setRead] = useState(is_seen);

  const handleClick = () => {
    if (!read) {
      setRead(true);
    }
  };

  return (
    <div
      className={
        from === "notification_modal"
          ? "NotificationCardContainerModal"
          : "NotificationCardContainerPage"
      }
      style={{
        backgroundColor: is_seen ? "transparent" : "#f5f7fd",
        cursor: "pointer",
      }}
      onClick={onClick || handleClick}
      ref={containerRef}
      // key={`notification${id}`}
    >
      <div className="notificationCardImageAndMessage">
        <img
          src={Icons.NOTIFICATION_ICON}
          alt="notificationCardIcon"
          className="notificationCardIconImage"
        />
        <div className="HeadingAndMessage">
          <h3 className="heading3" style={{ textTransform: "capitalize" }}>
            {heading}
          </h3>
          <p
            className={
              from === "notification_modal"
                ? "notificationModal"
                : "notificationPage"
            }
          >
            {message}
          </p>
          {from === "notification_modal" ? (
            <div className="description notificationTime">
              {`${moment(time).format("LT")} - ${moment(time).format(
                "MMMM-DD-YYYY"
              )}`}
            </div>
          ) : null}
        </div>
      </div>
      {from === "Notification_Detail" ? (
        <div className="description notificationTime">{`${moment(time).format(
          "LT"
        )} - ${moment(time).format("MMMM-DD-YYYY")}`}</div>
      ) : null}
    </div>
  );
};

export default NotificationCard;
