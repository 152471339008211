import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { API_CUSTOMERS } from "redux/services/apiTypes";

const initialState = {
  customerDetailLoading: false,
  customerDetailSuccess: false,
  customerDetailRes: null,
  customerDetailErr: null,
};

export const getCustomerDetail = createAsyncThunk(
  "detail/customer",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${API_CUSTOMERS}${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreCustomerDetail = createAsyncThunk(
  "customer/detail",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${API_CUSTOMERS}${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const customerDetailSlice = createSlice({
  name: "customerDetail",
  initialState,
  reducers: {
    clearHistoryBooking: (state) => {
      state.customerDetailLoading = false;
      state.customerDetailSuccess = false;
      state.customerDetailRes = null;
      state.customerDetailErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCustomerDetail.pending, (state) => {
        state.customerDetailLoading = true;
        state.customerDetailSuccess = false;
      })
      .addCase(getCustomerDetail.fulfilled, (state, action) => {
        state.customerDetailLoading = false;
        state.customerDetailSuccess = true;
        state.customerDetailRes = action.payload;
      })
      .addCase(getCustomerDetail.rejected, (state, action) => {
        state.customerDetailLoading = false;
        state.customerDetailSuccess = true;
        state.customerDetailErr = action.payload;
      })
      .addCase(getMoreCustomerDetail.pending, (state) => {
        state.customerDetailLoading = false;
        state.customerDetailSuccess = false;
      })
      .addCase(getMoreCustomerDetail.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: {
            customer_details: state.customerDetailRes.results?.customer_details,
            bookings: [
              ...state.customerDetailRes.results?.bookings,
              ...action.payload.results?.bookings,
            ],
          },
        };
        state.customerDetailLoading = false;
        state.customerDetailSuccess = true;
        state.customerDetailRes = res;
      })
      .addCase(getMoreCustomerDetail.rejected, (state, action) => {
        state.customerDetailLoading = false;
        state.customerDetailSuccess = true;
        state.customerDetailErr = action.payload;
      });
  },
});

export const { clearHistoryBooking } = customerDetailSlice.actions;

export default customerDetailSlice.reducer;
