import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { AVAILABILITY, STAFF_AVAILABILITY } from "redux/services/apiTypes";

const initialState = {
  manageAvailabilityLoading: false,
  manageAvailabilitySuccess: false,
  manageAvailabilityRes: null,
  manageAvailabilityErr: null,
};

export const getManageAvailability = createAsyncThunk(
  "manageAvailability/list",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: payload.id
        ? STAFF_AVAILABILITY + payload.id + "/availability/"
        : AVAILABILITY,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const manageAvailabilitySlice = createSlice({
  name: "manageAvailability",
  initialState,
  reducers: {
    clearManageAvailability: (state, action) => {
      state.manageAvailabilityLoading = false;
      state.manageAvailabilitySuccess = false;
      state.manageAvailabilityRes = null;
      state.manageAvailabilityErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getManageAvailability.pending, (state, action) => {
        state.manageAvailabilityLoading = false;
        state.manageAvailabilitySuccess = false;
      })
      .addCase(getManageAvailability.fulfilled, (state, action) => {
        state.manageAvailabilityLoading = false;
        state.manageAvailabilitySuccess = true;
        state.manageAvailabilityRes = action.payload;
      })
      .addCase(getManageAvailability.rejected, (state, action) => {
        state.manageAvailabilityLoading = false;
        state.manageAvailabilitySuccess = true;
        state.manageAvailabilityErr = action.payload;
      });
  },
});

export const { clearManageAvailability } = manageAvailabilitySlice.actions;

export default manageAvailabilitySlice.reducer;
