import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "./style.css";
import { Icons } from "utils/iconPath";

const Modal = ({ isOpen, onClose, children, modalHeading }) => {
  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (isOpen) {
      document.body.classList.add("modal-open");
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  // const keyHitHandler = (e) => {
  //   if (e.key === "esc") {
  //     modalHeading();
  //   }
  // };

  return ReactDOM.createPortal(
    <>
      <div className="modal-overlay" onClick={onClose}></div>
      <div className="modal">
        <div className="modalHeader">
          <div className="heading2 modalHeading">{modalHeading}</div>
          <div className="modalCloseButton">
            <img
              src={Icons.CLOSE_BUTTON}
              alt="modalButton"
              className="modalCloseButton"
              onClick={onClose}
            />
          </div>
        </div>
        <div className="modalSeparateDiv"></div>
        <div className="modal-content">{children}</div>
      </div>
    </>,
    document.getElementById("modal")
  );
};

export default Modal;
