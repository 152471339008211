import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { NOTIFICATION } from "redux/services/apiTypes";
import { PAGINATION_INITIAL_LIST_RES } from "utils/constant";

const initialState = {
  notificationLoading: false,
  notificationSuccess: false,
  notificationRes: PAGINATION_INITIAL_LIST_RES,
  notificationErr: null,
};

export const getNotification = createAsyncThunk(
  "notification/new",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NOTIFICATION}${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreNotification = createAsyncThunk(
  "moreNotification/new",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NOTIFICATION}${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    clearNotification: (state, action) => {
      state.notificationLoading = false;
      state.notificationSuccess = false;
      state.notificationRes = null;
      state.notificationErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.pending, (state, action) => {
        state.notificationLoading = true;
        state.notificationSuccess = false;
      })
      .addCase(getNotification.fulfilled, (state, action) => {
        state.notificationLoading = false;
        state.notificationSuccess = true;
        state.notificationRes = action.payload;
      })
      .addCase(getNotification.rejected, (state, action) => {
        state.notificationLoading = false;
        state.notificationSuccess = true;
        state.notificationErr = action.payload;
      })
      .addCase(getMoreNotification.pending, (state, action) => {
        state.notificationLoading = false;
        state.notificationSuccess = false;
      })
      .addCase(getMoreNotification.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [
            ...state.notificationRes.results,
            ...action.payload.results,
          ],
        };
        state.notificationLoading = false;
        state.notificationSuccess = true;
        state.notificationRes = res;
      })
      .addCase(getMoreNotification.rejected, (state, action) => {
        state.notificationLoading = false;
        state.notificationSuccess = true;
        state.notificationErr = action.payload;
      });
  },
});

export const { clearNotification } = notificationSlice.actions;

export default notificationSlice.reducer;

// export default function myBookingReducer(
//   state = {
//     myUpcomingBookingRes: PAGINATION_INITIAL_LIST_RES,
//     myOngoingBookingRes: PAGINATION_INITIAL_LIST_RES,
//     myPastBookingRes: PAGINATION_INITIAL_LIST_RES,
//     myBookingDetailRes: {},
//     myUpcomingBookingFetching: true,
//     myOngoingBookingFetching: true,
//     myPastBookingFetching: true,
//     onGoingServiceRes: PAGINATION_INITIAL_LIST_RES,
//     myBookingDetailFetching: false,
//   },
//   action
// ) {
//   switch (action.type) {
//     case actionTypes.GET_MY_UPCOMING_BOOKING_METHOD:
//       return {
//         ...state,
//         myUpcomingBookingFetching: state.myUpcomingBookingRes.results.length > 0 ? false : true,
//         myUpcomingBookingError: null,
//         isMyUpcomingBookingSuccess: false,
//       };
//       break;
//     case actionTypes.GET_MY_UPCOMING_BOOKING_PAGINATION_SUCCESS: {
//       let res = {
//         count: action.payload.count,
//         next: action.payload.next,
//         previous: action.payload.previous,
//         results: [...state.myUpcomingBookingRes.results, ...action.payload.results],
//       };
//       return {
//         ...state,
//         myUpcomingBookingFetching: false,
//         myUpcomingBookingError: null,
//         myUpcomingBookingRes: res,
//         isMyUpcomingBookingSuccess: true,
//       };
//       break;
//     }
//     case actionTypes.GET_MY_UPCOMING_BOOKING_SUCCESS:
//       return {
//         ...state,
//         myUpcomingBookingFetching: false,
//         myUpcomingBookingError: action.error,
//         myUpcomingBookingRes: action.payload,
//         isMyUpcomingBookingSuccess: true,
//       };
//       break;
//     case actionTypes.GET_MY_UPCOMING_BOOKING_FAIL:
//       return {
//         ...state,
//         myUpcomingBookingFetching: false,
//         myUpcomingBookingError: action.error,
//         myUpcomingBookingRes: state.myUpcomingBookingRes,
//         isMyUpcomingBookingSuccess: false,
//       };
//       break;
//     case actionTypes.GET_MY_UPCOMING_BOOKING_CLEAR:
//       return {
//         ...state,
//         myUpcomingBookingFetching: false,
//         myUpcomingBookingError: undefined,
//         myUpcomingBookingRes: PAGINATION_INITIAL_LIST_RES,
//         isMyUpcomingBookingSuccess: false,
//       };
//       break;

//     /////////////////////////////////////////////////////////////////////

//     case actionTypes.GET_MY_PAST_BOOKING_METHOD:
//       return {
//         ...state,
//         myPastBookingFetching: state.myPastBookingRes.results.length > 0 ? false : true,
//         myPastBookingError: null,
//         isMyPastBookingSuccess: false,
//       };
//       break;
//     case actionTypes.GET_MY_PAST_BOOKING_SUCCESS:
//       return {
//         ...state,
//         myPastBookingFetching: false,
//         myPastBookingError: null,
//         myPastBookingRes: action.payload,
//         isMyPastBookingSuccess: true,
//       };
//       break;
//     case actionTypes.GET_MY_PAST_BOOKING_PAGINATION_SUCCESS: {
//       let res = {
//         count: action.payload.count,
//         next: action.payload.next,
//         previous: action.payload.previous,
//         results: [...state.myPastBookingRes.results, ...action.payload.results],
//       };
//       return {
//         ...state,
//         myPastBookingFetching: false,
//         myPastBookingError: null,
//         myPastBookingRes: res,
//         isMyPastBookingSuccess: true,
//       };
//       break;
//     }
//     case actionTypes.GET_MY_PAST_BOOKING_FAIL:
//       return {
//         ...state,
//         myPastBookingFetching: false,
//         myPastBookingError: action.error,
//         myPastBookingRes: state.myPastBookingRes,
//         isMyPastBookingSuccess: false,
//       };
//       break;
//     case actionTypes.GET_MY_PAST_BOOKING_CLEAR:
//       return {
//         ...state,
//         myPastBookingFetching: false,
//         myPastBookingError: undefined,
//         myPastBookingRes: PAGINATION_INITIAL_LIST_RES,
//         isMyPastBookingSuccess: false,
//       };
//       break;
//     /////////////////////////////////////////////////////////////////////

//     case actionTypes.GET_MY_ONGOING_BOOKING_METHOD:
//       return {
//         ...state,
//         myOngoingBookingFetching: state.myOngoingBookingRes.results.length > 0 ? false : true,
//         myOngoingBookingError: null,
//         isMyOngoingBookingSuccess: false,
//       };
//       break;
//     case actionTypes.GET_MY_ONGOING_BOOKING_SUCCESS:
//       return {
//         ...state,
//         myOngoingBookingFetching: false,
//         myOngoingBookingError: null,
//         myOngoingBookingRes: action.payload,
//         isMyOngoingBookingSuccess: true,
//       };
//       break;
//     case actionTypes.GET_MY_ONGOING_BOOKING_PAGINATION_SUCCESS: {
//       let res = {
//         count: action.payload.count,
//         next: action.payload.next,
//         previous: action.payload.previous,
//         results: [...state.myOngoingBookingRes.results, ...action.payload.results],
//       };
//       return {
//         ...state,
//         myOngoingBookingFetching: false,
//         myOngoingBookingError: null,
//         myOngoingBookingRes: res,
//         isMyOngoingBookingSuccess: true,
//       };
//       break;
//     }
//     case actionTypes.GET_MY_ONGOING_BOOKING_FAIL:
//       return {
//         ...state,
//         myOngoingBookingFetching: false,
//         myOngoingBookingError: action.error,
//         myOngoingBookingRes: state.myOngoingBookingRes,
//         isMyOngoingBookingSuccess: false,
//       };
//       break;
//     case actionTypes.GET_MY_ONGOING_BOOKING_CLEAR:
//       return {
//         ...state,
//         myOngoingBookingFetching: false,
//         myOngoingBookingError: undefined,
//         myOngoingBookingRes: PAGINATION_INITIAL_LIST_RES,
//         isMyOngoingBookingSuccess: false,
//       };
//       break;

//     /////////////////////////////////////////////////////////////////////

//     case actionTypes.GET_MY_BOOKING_DETAIL_METHOD:
//       return {
//         ...state,
//         myBookingDetailFetching: true,
//         myBookingDetailError: null,
//         isMyBookingDetailSuccess: false,
//       };
//       break;
//     case actionTypes.GET_MY_BOOKING_DETAIL_SUCCESS:
//       return {
//         ...state,
//         myBookingDetailFetching: false,
//         myBookingDetailError: null,
//         myBookingDetailRes: action.payload,
//         isMyBookingDetailSuccess: true,
//       };
//       break;
//     case actionTypes.GET_MY_BOOKING_DETAIL_FAIL:
//       return {
//         ...state,
//         myBookingDetailFetching: false,
//         myBookingDetailError: action.error,
//         myBookingDetailRes: state.myBookingDetailRes,
//         isMyBookingDetailSuccess: false,
//       };
//       break;
//     case actionTypes.GET_MY_BOOKING_DETAIL_CLEAR:
//       return {
//         ...state,
//         myBookingDetailFetching: false,
//         myBookingDetailError: undefined,
//         myBookingDetailRes: PAGINATION_INITIAL_LIST_RES,
//         isMyBookingDetailSuccess: false,
//       };
//       break;

//     /////////////////////////////////////////////////////////////////////
//     case actionTypes.GET_ONGOING_SERVICE_METHOD:
//       return {
//         ...state,
//         onGoingServiceFetching: true,
//         onGoingServiceError: null,
//         isOnGoingServiceSuccess: false,
//       };
//       break;
//     case actionTypes.GET_ONGOING_SERVICE_SUCCESS:
//       return {
//         ...state,
//         onGoingServiceFetching: false,
//         onGoingServiceError: null,
//         onGoingServiceRes: action.payload,
//         isOnGoingServiceSuccess: true,
//       };
//       break;
//     case actionTypes.GET_ONGOING_SERVICE_FAIL:
//       return {
//         ...state,
//         onGoingServiceFetching: false,
//         onGoingServiceError: action.error,
//         onGoingServiceRes: state.onGoingServiceRes,
//         isOnGoingServiceSuccess: false,
//       };
//       break;
//     case actionTypes.GET_ONGOING_SERVICE_CLEAR:
//       return {
//         ...state,
//         onGoingServiceFetching: false,
//         onGoingServiceError: undefined,
//         onGoingServiceRes: PAGINATION_INITIAL_LIST_RES,
//         isOnGoingServiceSuccess: false,
//       };
//       break;

//     /////////////////////////////////////////////////////////////////////

//     default:
//       return state;
//   }
// }
