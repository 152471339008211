import "./style.css";
import { Icons } from "utils/iconPath";
import { useNavigate } from "react-router-dom";
import { SecondaryButton, TertiaryButton } from "common/Button";

const Header = ({
  isBack,
  Heading,
  count,
  ButtonTitle,
  buttonOnClick,
  buttonIsLoading,
  isButton,
  extraButton,
  extraButtonIcon,
  extraButtonIconPath,
  rightChildren,
  id,
  status,
  headerHeadingContainerStyle,
  isShareIcon,
  shareIconClick,
}) => {
  // hooks
  const navigate = useNavigate();

  // functions
  const handleBackButton = () => {
    navigate(-1);
  };

  const getStyle = () => {
    switch (status) {
      case "paid":
      case "created":
      case "accepted":
      case "in_transit":
      case "at_destination":
      case "completed_by_provider":
      case "completed":
        return {
          color: "white",
          backgroundColor: "#52c41a",
        };
      case "cancelled":
      case "cancelled_due_Payment":
      case "cancelled_by_supplier":
      case "cancelled_by_client":
        return {
          color: "white",
          backgroundColor: "#eb4646",
        };
      case "in_progress":
        return {
          color: "white",
          backgroundColor: "#faad14",
        };
      default:
        return {
          backgroundColor: "gray",
          color: "whi9te",
        };
    }
  };

  const style = getStyle();

  return (
    <div className="headerContainer">
      {isBack && (
        <div className="headerBackButton" onClick={handleBackButton}>
          <img
            src={Icons.BACK_ICON_BLUE}
            alt="backButton"
            className="headerBackIcon"
          />
        </div>
      )}
      <div className="headerRightContainer">
        <div className="headerBackButtonAndHeading">
          <div
            className="headerHeadingContainer"
            style={{
              ...headerHeadingContainerStyle,
            }}
          >
            <h2
              className="heading2"
              style={{
                marginTop: "1rem",
              }}
            >
              {Heading ? Heading + "" : ""}
              {id ? id : ""}
            </h2>
            {count && <h2 className="heading2">({count ?? ""})</h2>}
            {status && (
              <div className="headerStatus description" style={style}>
                {status.replace(/_/g, " ")}
              </div>
            )}
          </div>
          {isShareIcon && (
            <div className="shareIconContainer" onClick={shareIconClick}>
              <img src={Icons.SHARE} alt="" />
            </div>
          )}
        </div>
        <div className="headerRightButton">
          {isButton && (
            <SecondaryButton
              title={ButtonTitle}
              onClick={buttonOnClick}
              isLoading={buttonIsLoading}
              style={{
                color: buttonIsLoading ? "#3160d4" : "white",
              }}
              loadingColor={"white"}
            />
          )}
          {extraButton && (
            <TertiaryButton
              title={ButtonTitle}
              onClick={buttonOnClick}
              isLoading={buttonIsLoading}
              startIcon={extraButtonIcon}
              iconPath={extraButtonIconPath}
            />
          )}
          {rightChildren && (
            <div className="rightChildren">{rightChildren}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
