import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { CREATE_SUPPLIER } from "redux/services/apiTypes";

const initialState = {
  staffListLoading: false,
  staffListSuccess: false,
  staffListRes: null,
  staffListErr: null,
};

export const getStaffList = createAsyncThunk(
  "staff/list",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${CREATE_SUPPLIER}${payload.id}/staffs/${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreStaffList = createAsyncThunk(
  "staff/moreList",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${CREATE_SUPPLIER}${payload.id}/staffs/${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const staffListSlice = createSlice({
  name: "staffList",
  initialState,
  reducers: {
    clearStaffList: (state, action) => {
      state.staffListLoading = false;
      state.staffListSuccess = false;
      state.staffListRes = null;
      state.staffListErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getStaffList.pending, (state) => {
        state.staffListLoading = true;
        state.staffListSuccess = false;
      })
      .addCase(getStaffList.fulfilled, (state, action) => {
        state.staffListLoading = false;
        state.staffListSuccess = true;
        state.staffListRes = action.payload;
      })
      .addCase(getStaffList.rejected, (state, action) => {
        state.staffListLoading = false;
        state.staffListSuccess = true;
        state.staffListErr = action.payload;
      })
      .addCase(getMoreStaffList.pending, (state) => {
        state.staffListLoading = true;
        state.staffListSuccess = false;
      })
      .addCase(getMoreStaffList.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...state.staffListRes.results, ...action.payload.results],
        };
        state.staffListLoading = false;
        state.staffListSuccess = true;
        state.staffListRes = res;
      })
      .addCase(getMoreStaffList.rejected, (state, action) => {
        state.staffListLoading = false;
        state.staffListSuccess = true;
        state.staffListErr = action.payload;
      });
  },
});

export const { clearStaffList } = staffListSlice.actions;

export default staffListSlice.reducer;
