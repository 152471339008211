export const Icons = {
  LOGO: require("../assets/icons/logo.svg").default,
  SECURITY: require("../assets/icons/padlock.svg").default,
  BACK_ICON: require("../assets/icons/backIcon.svg").default,
  HOME: require("../assets/icons/home.svg").default,
  HOME_ACTIVE: require("../assets/icons/homeActive.svg").default,
  BOOKING: require("../assets/icons/booking.svg").default,
  BOOKING_ACTIVE: require("../assets/icons/bookingActive.svg").default,
  STAFF: require("../assets/icons/staff.svg").default,
  STAFF_ACTIVE: require("../assets/icons/staffActive.svg").default,
  PROFILE: require("../assets/icons/profile.svg").default,
  PROFILE_ACTIVE: require("../assets/icons/profileActive.svg").default,
  AVATAR: require("../assets/icons/avatar.svg").default,
  NOTIFICATION: require("../assets/icons/notification.svg").default,
  LOGOUT: require("../assets/icons/logout.svg").default,
  WALLET: require("../assets/icons/wallet.svg").default,
  DOWN_ARROW: require("../assets/icons/down_arrow.svg").default,
  CARD_ICON: require("../assets/icons/cardIcon.svg").default,
  CARD_ARROW: require("../assets/icons/cardArrow.svg").default,
  CALENDAR: require("../assets/icons/calendar.svg").default,
  TIME: require("../assets/icons/time.svg").default,
  LOCATION: require("../assets/icons/location.svg").default,
  NO_DATA_FOUND: require("../assets/icons/noDataFoundIcon.svg").default,
  UP_ARROW: require("../assets/icons/upArrow.svg").default,
  MENU: require("../assets/icons/menu.svg").default,
  CLOSE: require("../assets/icons/close.svg").default,
  BACK_ICON_BLUE: require("../assets/icons/backIconBlue.svg").default,
  DUMMY_PROFILE: require("../assets/icons/dummyProfile.svg").default,
  ADD_IMAGE: require("../assets/icons/addImage.svg").default,
  EDIT: require("../assets/icons/edit.svg").default,
  CLOSE_BUTTON: require("../assets/icons/closeButton.svg").default,
  NOTIFICATION_ACTIVE: require("../assets/icons/notificationActive.svg")
    .default,
  MARK_READ: require("../assets/icons/markRead.svg").default,
  NOTIFICATION_ICON: require("../assets/icons/notificationIcon.svg").default,
  HELP: require("../assets/icons/help.svg").default,
  EARNING: require("../assets/icons/earning.svg").default,
  ABOUT_US: require("../assets/icons/aboutUs.svg").default,
  MANAGE_AVAILABILITY: require("../assets/icons/manageAvailability.svg")
    .default,
  FACEBOOK: require("../assets/icons/facebook.svg").default,
  TWITTER: require("../assets/icons/twitter.svg").default,
  YOUTUBE: require("../assets/icons/youtube.svg").default,
  LINKEDIN: require("../assets/icons/linkedin.svg").default,
  INSTAGRAM: require("../assets/icons/instagram.svg").default,
  EMAIL: require("../assets/icons/email.svg").default,
  PHONE: require("../assets/icons/phone.svg").default,
  FAQ_OPEN: require("../assets/icons/faqOpen.svg").default,
  FAQ_CLOSE: require("../assets/icons/faqClose.svg").default,
  PLAY: require("../assets/icons/playIcon.svg").default,
  PHONE_SMALL: require("../assets/icons/phoneSmall.svg").default,
  MENU_OPTION: require("../assets/icons/menuOption.svg").default,
  LEADER: require("../assets/icons/leader.svg").default,
  WARNING: require("../assets/icons/warning.svg").default,
  WARNING_PRIMARY: require("../assets/icons/warningPrimary.svg").default,
  H_MENU: require("../assets/icons/menu2.svg").default,
  PAUSE: require("../assets/icons/pause.svg").default,
  MY_SERVICE: require("../assets/icons/myService.svg").default,
  RATING: require("../assets/icons/rating.svg").default,
  RATING_NONE: require("../assets/icons/ratingNone.svg").default,
  RATING_HALF: require("../assets/icons/half-star.svg").default,
  INFORMATION_ICON: require("../assets/icons/informationIcon.svg").default,
  TRANSLATION: require("../assets/icons/translate.svg").default,
  LEFT_ARROW: require("../assets/icons/leftArrow2.svg").default,
  RIGHT_ARROW: require("../assets/icons/rightArrow2.svg").default,
  CALENDAR_ACTIVE: require("../assets/icons/sideCalendar.svg").default,
  MULTI_BOOKING: require("../assets/icons/multiBooking.svg").default,
  CALENDAR_SIDE_ACTIVE: require("../assets/icons/sideCalendarActive.svg")
    .default,
  RECURRING_ICON: require("../assets/icons/recurringIcon.svg").default,
  PDF_ICON: require("../assets/icons/PDFIcon.svg").default,
  SIDE_BAR: require("../assets/icons/sideBarCalendar.svg").default,
  DELETE: require("../assets/icons/delete.png"),
  SHARE: require("../assets/icons/share.png"),
  SQUARE_MINUS: require("../assets/icons/squareMinus.png"),
  SQUARE_PLUS: require("../assets/icons/squarePlus.png"),
  RADIO_CHECKED: require("../assets/icons/radioChecked.svg").default,
  RADIO_UNCHECKED: require("../assets/icons/radioUnchecked.svg").default,
  FILL_EDIT_ICON: require("../assets/icons/fill_edit_icon.svg").default,
  RATING_ADN_REVIEW: require("../assets/icons/rating_&_review.svg").default,
  MENU_DOTS: require("../assets/icons/menuDots.svg").default,
  ARROW_SMALL_RIGHT: require("../assets/icons/arrowSmallLeft.svg").default,
  ARROW_SMALL_LEFT: require("../assets/icons/arrow-small-left.svg").default,
  EYE: require("../assets/icons/eye.svg").default,
  EDIT_PENCIL: require("../assets/icons/edit_pencil.svg").default,
  CUSTOMER: require("../assets/icons/customer.svg").default,
  MAIL_SMALL: require("../assets/icons/mailSmall.svg").default,
  EARNING_TAB: require("../assets/icons/earningTab.svg").default,
  MY_SERVICE_TAB: require("../assets/icons/myServiceTab.svg").default,
  COPY_ICON: require("../assets/icons/copy_icon.svg").default,
  PROFILE_LOCATION: require("../assets/icons/location_profile.svg").default,
  CHECKED: require("../assets/icons/checked.svg").default,
  UNCHECKED: require("../assets/icons/unchecked.svg").default,
  ADDITIONAL_INFORMATION: require("../assets/icons/additional-information.svg")
    .default,
};
