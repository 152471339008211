import { Icons } from "utils/iconPath";
import "./style.css";

export const RadioButton = ({ label, onChange, checked, isRequired }) => {
  return (
    <div className="radioContainer" onClick={onChange}>
      <input
        id={label}
        name="radio"
        type="radio"
        onChange={onChange}
        checked={checked}
        className="radio"
        required={isRequired}
      />
      <div className="radioButtonImageContainer">
        {checked ? (
          <img
            src={Icons?.RADIO_CHECKED}
            alt="checked"
            className="radioButtons"
            onClick={onChange}
          />
        ) : (
          <img
            src={Icons?.RADIO_UNCHECKED}
            alt="unchecked"
            className="radioButtons"
            onClick={onChange}
          />
        )}
      </div>
      <label id={label} className="bodyText radioLabel">
        {label}
      </label>
    </div>
  );
};
