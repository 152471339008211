import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { MY_SERVICES } from "redux/services/apiTypes";

const initialState = {
  myServiceLoading: false,
  myServiceSuccess: false,
  myServiceRes: null,
  myServiceErr: null,
};

export const getMyService = createAsyncThunk(
  "myService/list",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: MY_SERVICES + payload?.query,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreMyServices = createAsyncThunk(
  "moreMyServices/list",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: MY_SERVICES + payload?.query,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const myServiceSlice = createSlice({
  name: "myService",
  initialState,
  reducers: {
    clearMyService: (state, action) => {
      state.myServiceLoading = false;
      state.myServiceSuccess = false;
      state.myServiceRes = null;
      state.myServiceErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMyService.pending, (state, action) => {
        state.myServiceLoading = false;
        state.myServiceSuccess = false;
      })
      .addCase(getMyService.fulfilled, (state, action) => {
        state.myServiceLoading = false;
        state.myServiceSuccess = true;
        state.myServiceRes = action.payload;
      })
      .addCase(getMyService.rejected, (state, action) => {
        state.myServiceLoading = false;
        state.myServiceSuccess = true;
        state.myServiceErr = action.payload;
      })
      .addCase(getMoreMyServices.pending, (state, action) => {
        state.myServiceLoading = false;
        state.myServiceSuccess = false;
      })
      .addCase(getMoreMyServices.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...state.myServiceRes.results, ...action.payload.results],
        };
        state.myServiceLoading = false;
        state.myServiceSuccess = true;
        state.myServiceRes = res;
      })
      .addCase(getMoreMyServices.rejected, (state, action) => {
        state.myServiceLoading = false;
        state.myServiceSuccess = false;
        state.myServiceErr = action.payload;
      });
  },
});

export const { clearMyService } = myServiceSlice.actions;

export default myServiceSlice.reducer;
