import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { NEW_BOOKING } from "redux/services/apiTypes";

const initialState = {
  bookingDetailLoading: false,
  bookingDetailSuccess: false,
  bookingDetailRes: null,
  bookingDetailErr: null,
};

export const getBookingDetails = createAsyncThunk(
  "booking/detail",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}${data}/`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const bookingDetailSlice = createSlice({
  name: "bookingDetail",
  initialState,
  reducers: {
    clearBookingDetail: (state, action) => {
      state.bookingDetailLoading = false;
      state.bookingDetailSuccess = false;
      state.bookingDetailRes = null;
      state.bookingDetailErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingDetails.pending, (state, action) => {
        state.bookingDetailLoading = true;
        state.bookingDetailSuccess = false;
      })
      .addCase(getBookingDetails.fulfilled, (state, action) => {
        state.bookingDetailLoading = false;
        state.bookingDetailSuccess = true;
        state.bookingDetailRes = action.payload;
      })
      .addCase(getBookingDetails.rejected, (state, action) => {
        state.bookingDetailLoading = false;
        state.bookingDetailSuccess = true;
        state.bookingDetailErr = action.payload;
      });
  },
});

export const { clearBookingDetail } = bookingDetailSlice.actions;

export default bookingDetailSlice.reducer;
