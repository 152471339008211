import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { NEW_BOOKING } from "redux/services/apiTypes";

const initialState = {
  bookingCalendarLoading: false,
  bookingCalendarSuccess: false,
  bookingCalendarRes: null,
  bookingCalendarErr: null,
};

export const getBookingCalendars = createAsyncThunk(
  "bookings/calendar",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}?slot_after=${payload.slot_after}&slot_before=${payload?.slot_before}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const bookingCalendarSlice = createSlice({
  name: "bookingCalendar",
  initialState,
  reducers: {
    clearBookingCalendar: (state, action) => {
      state.bookingCalendarLoading = false;
      state.bookingCalendarSuccess = false;
      state.bookingCalendarRes = null;
      state.bookingCalendarErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBookingCalendars.pending, (state, action) => {
        state.bookingCalendarLoading = true;
        state.bookingCalendarSuccess = false;
      })
      .addCase(getBookingCalendars.fulfilled, (state, action) => {
        state.bookingCalendarLoading = false;
        state.bookingCalendarSuccess = true;
        state.bookingCalendarRes = action.payload;
      })
      .addCase(getBookingCalendars.rejected, (state, action) => {
        state.bookingCalendarLoading = false;
        state.bookingCalendarSuccess = true;
        state.bookingCalendarErr = action.payload;
      });
  },
});

export const { clearBookingCalendar } = bookingCalendarSlice.actions;

export default bookingCalendarSlice.reducer;
