import React from "react";
import "./SwitchButton.css";

const SwitchButton = ({
  onChange,
  value,
  switchId,
  isRequired,
  classNameSlider,
  classNameSwitch,
}) => {
  return (
    <label className={`switch ${classNameSwitch}`}>
      <input
        type="checkbox"
        name=""
        id={switchId}
        checked={value}
        onChange={onChange}
        required={isRequired}
      />
      <span className={`slider ${classNameSlider}`}></span>
    </label>
  );
};

export default SwitchButton;
