import { useEffect, useRef, useState } from "react";
import "./notificationModal.css";
import { Icons } from "utils/iconPath";
import { useTranslation } from "react-i18next";
import NotificationCard from "../notificationCard/NotificationCard";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import "react-modern-drawer/dist/index.css";
import BookingNoDataFound from "components/bookings/bookingNoDataFound";
import Loading from "common/Loading";
import {
  getMoreNotification,
  getNotification,
} from "redux/Slice/notificationSlice/notificationSlice";
import {
  GET_LIST,
  GET_PAGINATION_LIST,
  NOTIFICATION_TYPE,
} from "utils/constant";
import { CButton } from "common/Button";
import { getMessaging, onMessage } from "firebase/messaging";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getNewBookings } from "redux/Slice/bookingSlice/newBookingSlice";

const NotificationModal = ({ isOpen, setIsOpen }) => {
  // hooks
  const { t } = useTranslation("notification");
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  const navigator = useNavigate();

  const { notificationRes, notificationLoading } = useSelector(
    (state) => ({
      notificationRes: state.notification.notificationRes,
      notificationLoading: state.notification.notificationLoading,
    }),
    shallowEqual
  );

  const [limit] = useState(10);
  const [start, setStart] = useState(0);
  const [notificationMessageData, setNotificationMessageData] = useState("");

  const initApiCall = () => {
    setStart(0);
    getNotifications(0, GET_LIST);
  };

  const onEndReach = () => {
    if (notificationRes.next !== null) {
      let str = start + limit;
      setStart(str);
      getNotifications(str, GET_PAGINATION_LIST);
    }
  };

  const getNotifications = (str, listType) => {
    const query = `?start=${str}&limit=${limit}`;
    const payload = {
      query: query,
    };
    if (listType === GET_LIST) dispatch(getNotification(payload));
    else if (listType === GET_PAGINATION_LIST)
      dispatch(getMoreNotification(payload));
  };

  const notificationClickHandler = (type, id) => {
    switch (type) {
      case NOTIFICATION_TYPE.SERVICE:
        navigator(`/bookings/detail/${id}`, {
          state: {
            recurring: false,
          },
        });
        break;
      case NOTIFICATION_TYPE.PROFILE:
        navigator(`/profile`);
        break;

      case NOTIFICATION_TYPE.SYSTEM:
        navigator("/");
        break;

      case NOTIFICATION_TYPE.WALLET:
        navigator(`/profile/earning`);
        break;

      case NOTIFICATION_TYPE.NEW_BOOKING:
        navigator(`/bookings/detail/${id}`, {
          state: {
            recurring: false,
          },
        });
        const query = `?new=true&start=${0}&limit=${limit}`;
        const payload = {
          query: query,
        };
        dispatch(getNewBookings(payload));
        break;
      case NOTIFICATION_TYPE.RECURRING_BOOKING:
        navigator(`/bookings/detail/${id}`, {
          state: {
            recurring: true,
          },
        });
        const recurringQuery = `?new=true&start=${0}&limit=${limit}`;
        const recurringPayload = {
          query: recurringQuery,
        };
        dispatch(getNewBookings(recurringPayload));
        break;
    }
  };

  useEffect(() => {
    initApiCall();
  }, [isOpen]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.body.classList.add("modal-open");
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.body.classList.remove("modal-open");
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isOpen]);

  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
  window.addEventListener("resize", () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--vh", `${vh}px`);
  });

  useEffect(() => {
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      setNotificationMessageData(payload);
    });

    if (notificationMessageData) {
      toast(
        (t) => (
          <NotificationCard
            heading={notificationMessageData?.notification?.title}
            message={notificationMessageData?.notification?.body}
            onClick={() => {
              const notificationType = notificationMessageData?.data?.type;
              const notificationBookingId = notificationMessageData?.data?.id;
              notificationClickHandler(notificationType, notificationBookingId);
              toast.dismiss(t.id);
            }}
          />
        ),
        {
          duration: 5000,
          style: {
            padding: "0rem",
            borderRadius: "3.8rem",
          },
        }
      );

      if (
        NOTIFICATION_TYPE.NEW_BOOKING === notificationMessageData?.data?.type
      ) {
        const query = `?new=true&start=${0}&limit=${limit}`;
        const payload = {
          query: query,
        };
        dispatch(getNewBookings(payload));
      }
    }
  }, [notificationMessageData]);

  return (
    <>
      <div className="notificationModalContainer" ref={containerRef}>
        <div className="notificationModalHeader">
          <h2 className="heading2">
            {t("notifications")}
            {notificationRes?.count ? `(${notificationRes?.count})` : ""}
          </h2>
          <img
            src={Icons.CLOSE_BUTTON}
            alt="notificationCloseButton"
            className="notificationCloseButton"
            onClick={() => setIsOpen(!isOpen)}
          />
        </div>
        {notificationLoading ? (
          <Loading />
        ) : (
          <>
            {notificationRes?.results?.length === 0 ? (
              <div className="notificationNotFoundScreenDiv">
                <BookingNoDataFound message={"Notifications not found!"} />
              </div>
            ) : (
              <div className="notificationCardContainer">
                {notificationRes?.results?.map((item, index) => (
                  <NotificationCard
                    from={"notification_modal"}
                    id={item.id}
                    heading={item.title}
                    message={item.message}
                    time={item.created}
                    key={item.id + index}
                    is_seen={item.is_seen}
                    onClick={() => {
                      notificationClickHandler(
                        item?.notification_type,
                        item?.object_id
                      );
                      setIsOpen(!isOpen);
                    }}
                  />
                ))}
                <div style={{ width: "100%" }}>
                  {notificationRes?.next === null ? null : (
                    <CButton
                      title={"load more"}
                      onClick={() => {
                        onEndReach();
                      }}
                      style={{
                        width: "100%",
                        backgroundColor: "#FAFAFA",
                        color: "#202020",
                        textTransform: "capitalize",
                      }}
                    />
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default NotificationModal;
