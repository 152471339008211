import React, { useEffect, useState } from "react";
import {
  GoogleMap,
  InfoWindowF,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import "./style.css";
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from "use-places-autocomplete";
import { Toaster } from "react-hot-toast";
import axios from "axios";
import { GOOGLE_MAP_API_KEY } from "utils/constant";
import Input from "common/Input";

function GoogleMapComponent({
  updateLocation,
  setFullAddress,
  fullAddress,
  existingLocation,
}) {
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: { country: "LK" },
    },
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_API_KEY,
    libraries: ["places"],
  });

  const [map, setMap] = useState(null);
  const [location, setLocation] = useState({
    lng: 79.861244,
    lat: 6.927079,
  });
  const [showMarker, setShowMarker] = useState(false);

  const getFullAddress = (latitude, longitude) => {
    axios
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${GOOGLE_MAP_API_KEY}`
      )
      .then((res) => {
        setFullAddress(res?.data?.results[0]?.formatted_address);
      })
      .catch((err) => {
        Toaster({
          type: "error",
          message: err.message,
        });
      });
  };

  const onLoad = React.useCallback(function callback(map) {
    setMap(map);
  }, []);

  const onUpdateLocation = (loc) => updateLocation(loc);

  function error(err) {
  }

  const getCurrentLocation = () => {
    navigator.geolocation.getCurrentPosition(function (position) {
      setLocation({
        lng: position.coords.longitude,
        lat: position.coords.latitude,
      });
      onUpdateLocation({
        lng: position.coords.longitude,
        lat: position.coords.latitude,
      });
    }, error);
  };

  function handleMarkerDrag(event) {
    setLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    onUpdateLocation({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    getFullAddress(event.latLng.lat(), event.latLng.lng());
  }

  const handleSelect = async (address) => {
    setValue(address, false);
    const results = await getGeocode({ address });
    const { lat, lng } = await getLatLng(results[0]);
    setLocation({ lat, lng });
    onUpdateLocation({
      lat: lat,
      lng: lng,
    });
    clearSuggestions();
    if (lat && lng) {
      getFullAddress(lat, lng);
    }
  };

  useEffect(() => {
    if (
      existingLocation &&
      existingLocation.latitude &&
      existingLocation.longitude
    ) {
      setLocation({
        lng: parseFloat(existingLocation.longitude),
        lat: parseFloat(existingLocation.latitude),
      });
      onUpdateLocation({
        lng: parseFloat(existingLocation.longitude),
        lat: parseFloat(existingLocation.latitude),
      });
      getFullAddress(existingLocation?.latitude, existingLocation?.longitude);
    } else {
      getCurrentLocation();
    }
  }, [existingLocation]);

  useEffect(() => {
    if (map && location) {
      setShowMarker(true);
      getFullAddress(location?.lat, location?.lng);
    }
  }, [map, location]);

  return isLoaded ? (
    <div>
      <div className="addressSuggestionContainer">
        <Input
          rightIconBGVisibility
          label="Map Location"
          onChange={(e) => setValue(e.target.value)}
          disabled={!ready}
          placeholder="Search location here..."
          style={{
            marginBottom: "1.5rem",
          }}
          value={value}
        />
        {status === "OK" && (
          <div className="addressSuggestionsMenu">
            {status === "OK" &&
              data.map(({ place_id, description }) => (
                <p
                  key={place_id}
                  onClick={() => handleSelect(description)}
                  className="addressSuggestionsItem"
                >
                  {description}
                </p>
              ))}
          </div>
        )}
      </div>
      {location && (
        <GoogleMap
          mapContainerClassName="containerStyle"
          center={location}
          zoom={16}
          onLoad={onLoad}
          clickableIcons={true}
          options={{
            streetViewControlOptions: false,
            streetView: false,
            streetViewControl: false,
            fullscreenControl: false,
            keyboardShortcuts: false,
            mapTypeControl: false,
          }}
        >
          {showMarker && (
            <Marker
              position={location}
              draggable={true}
              onDrag={handleMarkerDrag}
              title={fullAddress}
            >
              <InfoWindowF position={location}>
                <div>{fullAddress}</div>
              </InfoWindowF>
            </Marker>
          )}
        </GoogleMap>
      )}
    </div>
  ) : (
    <></>
  );
}

export default React.memo(GoogleMapComponent);
