import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { MY_RATING } from "redux/services/apiTypes";

const initialState = {
  ratingListLoading: false,
  ratingListSuccess: false,
  ratingListRes: null,
  ratingListErr: null,
};

export const getRatingLists = createAsyncThunk(
  "bookings/newRating",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${MY_RATING}${payload?.query}`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const getMoreRatingLists = createAsyncThunk(
  "bookings/moreNew",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${MY_RATING}${payload.query}`,
      apiType: "GET",
      payload: {},
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const ratingListSlice = createSlice({
  name: "ratingList",
  initialState,
  reducers: {
    clearRatingList: (state, action) => {
      state.ratingListLoading = false;
      state.ratingListSuccess = false;
      state.ratingListRes = null;
      state.ratingListErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRatingLists.pending, (state, action) => {
        state.ratingListLoading = true;
        state.ratingListSuccess = false;
      })
      .addCase(getRatingLists.fulfilled, (state, action) => {
        state.ratingListLoading = false;
        state.ratingListSuccess = true;
        state.ratingListRes = action.payload;
      })
      .addCase(getRatingLists.rejected, (state, action) => {
        state.ratingListLoading = false;
        state.ratingListSuccess = true;
        state.ratingListErr = action.payload;
      })
      .addCase(getMoreRatingLists.pending, (state, action) => {
        state.ratingListLoading = false;
        state.ratingListSuccess = false;
      })
      .addCase(getMoreRatingLists.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...state.ratingListRes.results, ...action.payload.results],
        };
        state.ratingListLoading = false;
        state.ratingListSuccess = true;
        state.ratingListRes = res;
      })
      .addCase(getMoreRatingLists.rejected, (state, action) => {
        state.ratingListLoading = false;
        state.ratingListSuccess = true;
        state.ratingListErr = action.payload;
      });
  },
});

export const { clearRatingList } = ratingListSlice.actions;

export default ratingListSlice.reducer;
