import "./style.css";
import { useTranslation } from "react-i18next";
import { Icons } from "utils/iconPath";

const BookingNoDataFound = ({ message, children, otherMessage, style }) => {
  const { t } = useTranslation("dataNotFound");
  return (
    <>
      <div
        className="bookingNoDataFoundContainer"
        style={{
          ...style,
        }}
      >
        <div className="bookingNoDataFoundSubContainer">
          <div className="bookingNoDataFoundImageContainer">
            <img src={Icons.NO_DATA_FOUND} alt="" />
          </div>
          <div className="bookingNoDataFoundHeading heading2">
            {t("whoops")}
          </div>
          <div className="bookingNoDataFoundSubHeading">
            <p
              className="bodyText"
              style={{
                lineHeight: "2rem",
              }}
            >
              {message}
            </p>
            <p className="bodyText">{otherMessage}</p>
          </div>
          {children && (
            <div className="NotFoundButtonContainer">{children}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default BookingNoDataFound;
