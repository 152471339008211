import React, { useEffect, useRef, useState } from "react";
import "./style.css";
import { Icons } from "utils/iconPath";

const CustomDropDown = ({
  style = {},
  customClassName,
  label,
  dropDownData,
  setSelectedValue,
  selectedValue,
  displayKey,
  valueKey,
  placeholder,
  disabled,
  dynamicValue,
  styleMenuContainer,
  isRequired,
  onChange,
  displayCustomListItem,
  customListItem,
  isValueSet,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [displayName, setDisplayName] = useState(dynamicValue || "");
  const containerRef = useRef(null);
  const [show, setShow] = useState(false);
  const [customStringRender, setCustomStringRender] = useState();

  const Zero = 0;
  let customString;

  const selectedValueHandler = (value) => {
    setSelectedValue(value);
    setIsOpen(false);
  };

  const handleClick = () => {
    if (disabled) {
      setIsOpen(false);
      return;
    }
    setIsOpen(!isOpen);
    setShow(true);
  };

  useEffect(() => {
    if (!isValueSet) {
      if (dropDownData) {
        const found = dropDownData?.find((element) => {
          return element[valueKey] === selectedValue;
        });

        setDisplayName(found?.[displayKey]);
      }
    }
  }, [
    selectedValue,
    displayName,
    dropDownData,
    valueKey,
    displayKey,
    ,
    isValueSet,
  ]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
        setShow(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    setDisplayName(dynamicValue || "");
    setCustomStringRender(dynamicValue);
  }, [dynamicValue, isValueSet]);

  useEffect(() => {
    if (!isValueSet) {
      if (dropDownData) {
        const found = dropDownData?.find((element) => {
          return element[valueKey] === selectedValue;
        });

        setDisplayName(found?.[displayKey]);
      }
    }
  }, [
    selectedValue,
    displayName,
    dropDownData,
    valueKey,
    displayKey,
    isValueSet,
  ]);

  return (
    <div
      style={style}
      ref={containerRef}
      className={`customDropDownContainer ${
        disabled ? "disabledDropDown" : ""
      }`}
      onChange={onChange}
    >
      {label && (
        <div className="customDropDownLabelContainer">
          <p className="customDropDownLabel">{label}</p>
          {!isRequired && <p className="bodyText optionalText">Optional</p>}
        </div>
      )}
      <button
        className={`customDropDownValueContainer bodyText ${customClassName}`}
        onClick={handleClick}
        style={{
          border: show ? "0.5rem solid #3160d4" : "",
        }}
        disabled={disabled}
        type="button"
      >
        {/* {displayName && selectedValue ? (
          displayName
        ) : (
          <p className="bodyText placeholder">{placeholder || "Select"}</p>
        )} */}
        <span className="subFilterItem textOverFlowDots">
          {displayCustomListItem
            ? customStringRender || placeholder
            : displayName || placeholder}
        </span>

        <img
          src={Icons?.DOWN_ARROW}
          alt="customDropDownArrowDown"
          className={`customDropDownArrowDown ${
            isOpen ? "customDropDownArrowDownActive" : ""
          }`}
        />
      </button>
      {isOpen && (
        <div
          className="customDropDownMenuContainer"
          style={{
            overflowY: dropDownData.length >= 5 ? "scroll" : "hidden",
            ...styleMenuContainer,
          }}
        >
          {dropDownData.length <= Zero ? (
            <div
              className="bodyText"
              style={{
                textAlign: "center",
                opacity: 1,
              }}
            >
              No Data Found
            </div>
          ) : (
            <>
              {dropDownData.map((item, index) => (
                <li
                  onClick={() => {
                    selectedValueHandler(item[valueKey]);
                    setDisplayName(item[displayKey]);
                    setShow(false);
                    customListItem && customListItem(item);
                    customString = customListItem && customListItem(item);
                    setCustomStringRender(customString);
                  }}
                  className={
                    selectedValue === item[valueKey]
                      ? "customDropDownElementActive bodyText"
                      : "customDropDownElement bodyText"
                  }
                  key={index + 1}
                >
                  {displayCustomListItem
                    ? customListItem && customListItem(item)
                    : item[displayKey]}
                </li>
              ))}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default CustomDropDown;
