import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { TRANSACTION } from "redux/services/apiTypes";

const initialState = {
  transactionLoading: false,
  transactionSuccess: false,
  transactionRes: null,
  transactionErr: null,
};

export const getTransaction = createAsyncThunk(
  "transaction/list",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${TRANSACTION}${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// More transaction like pagination
export const getMoreTransaction = createAsyncThunk(
  "transaction/MoreList",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${TRANSACTION}${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const transactionSlice = createSlice({
  name: "transaction",
  initialState,
  reducers: {
    clearTransaction: (state, action) => {
      state.transactionLoading = false;
      state.transactionSuccess = false;
      state.transactionRes = null;
      state.transactionErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTransaction.pending, (state, action) => {
        state.transactionLoading = true;
        state.transactionSuccess = false;
      })
      .addCase(getTransaction.fulfilled, (state, action) => {
        state.transactionLoading = false;
        state.transactionSuccess = true;
        state.transactionRes = action.payload;
      })
      .addCase(getTransaction.rejected, (state, action) => {
        state.transactionLoading = false;
        state.transactionSuccess = false;
        state.transactionErr = action.payload;
      })
      .addCase(getMoreTransaction.pending, (state, action) => {
        state.transactionLoading = false;
        state.transactionSuccess = false;
      })
      .addCase(getMoreTransaction.fulfilled, (state, action) => {
        let res = {
          count: action.payload.count,
          next: action.payload.next,
          previous: action.payload.previous,
          results: [...state.transactionRes.results, ...action.payload.results],
        };
        state.transactionLoading = false;
        state.transactionSuccess = true;
        state.transactionRes = res;
      })
      .addCase(getMoreTransaction.rejected, (state, action) => {
        state.transactionLoading = false;
        state.transactionSuccess = false;
        state.transactionErr = action.payload;
      });
  },
});

export const { clearTransaction } = transactionSlice.actions;

export default transactionSlice.reducer;
