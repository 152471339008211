import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { SINGLE_SERVICE } from "redux/services/apiTypes";

const initialState = {
  serviceDetailLoading: false,
  serviceDetailSuccess: false,
  serviceDetailRes: null,
  serviceDetailErr: null,
};

export const getServiceDetails = createAsyncThunk(
  "service/detail",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${SINGLE_SERVICE}${data}/`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const serviceDetailSlice = createSlice({
  name: "serviceDetail",
  initialState,
  reducers: {
    clearServiceDetail: (state) => {
      state.serviceDetailLoading = false;
      state.serviceDetailSuccess = false;
      state.serviceDetailRes = null;
      state.serviceDetailErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getServiceDetails.pending, (state) => {
        state.serviceDetailLoading = true;
        state.serviceDetailSuccess = false;
      })
      .addCase(getServiceDetails.fulfilled, (state, action) => {
        state.serviceDetailLoading = false;
        state.serviceDetailSuccess = true;
        state.serviceDetailRes = action.payload;
      })
      .addCase(getServiceDetails.rejected, (state, action) => {
        state.serviceDetailLoading = false;
        state.serviceDetailSuccess = false;
        state.serviceDetailErr = action.payload;
      });
  },
});

export const { clearServiceDetail } = serviceDetailSlice.actions;

export default serviceDetailSlice.reducer;
