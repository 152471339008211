import Header from "common/Header";
import "./style.css";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Input, { TextArea } from "common/Input";
import CustomDropDown from "common/dropdown";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getErrorMessage, isValidation } from "utils";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  API_CUSTOMER_VALIDATION,
  API_SERVICES,
  API_USERS,
} from "redux/services/apiTypes";
import apiCall from "redux/services/apiCall";
import toast from "react-hot-toast";
import moment from "moment";
import { getSupplierProfile } from "redux/Slice/supplierProfileSlice/supplierProfileSlice";
import GoogleMap from "components/GoogleMap";
import { DynamicFields } from "./dynamicFields";
import { SERVICE_FIELD_TYPE } from "utils/constant";
import { SecondaryButton } from "common/Button";
import { getCustomerDetail } from "redux/Slice/customerDetailSlice/customerDetailSlice";
import { Icons } from "utils/iconPath";
import EditPriceModal from "./eidtPrice";
import CheckBox from "common/Checkbox";
import CustomCalendar from "common/CustomCalender";

export const CreateBooking = () => {
  // Hooks
  const { t } = useTranslation("createBooking");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let customerId = searchParams?.get("id");

  // Redux States
  const { supplierProfileRes, customerDetailRes } = useSelector(
    (state) => ({
      supplierProfileRes: state?.supplierProfile?.supplierProfileRes,
      customerDetailRes: state.customerDetail.customerDetailRes,
    }),
    shallowEqual
  );

  // Variables
  const supplierId = supplierProfileRes?.id;
  const minDateTime = new Date().toISOString().slice(0, 16);

  // Local States
  const [loading, setLoading] = useState();
  const [reRender, setReRender] = useState();
  const [enableEditPrice, setEnableEditPrice] = useState(false);
  const [name, setName] = useState({
    value: "",
    error: "",
    success: false,
  });
  const [number, setNumber] = useState({
    value: "",
    error: "",
    success: false,
  });
  const [email, setEmail] = useState({
    value: "",
    error: "",
    success: false,
  });
  const [service, setService] = useState();
  const [servicesRes, setServicesRes] = useState(
    supplierProfileRes?.services || []
  );
  const [serviceDataRes, setServiceDataRes] = useState([]);
  const [useCustomDateAndTimeSlot, setUseCustomDateAndTimeSlot] =
    useState(false);
  const [customDateAndTimeSlot, setCustomDateAndTimeSlot] = useState();
  const [date, setDate] = useState();
  const [datesRes, setDatesRes] = useState([]);
  const [timeSlot, setTimeSlot] = useState();
  const [timeSlotsRes, setTimeSlotsRes] = useState([]);
  const [mapAddress, setMapAddress] = useState("");
  const [mapCoordinates, setMapCoordinates] = useState({
    lat: 6.927079,
    lng: 79.861244,
  });
  const [villaOrApartment, setVillaOrApartment] = useState({
    value: "",
    error: "",
    success: false,
  });
  const [buildingOrStreet, setBuildingOrStreet] = useState({
    value: "",
    error: "",
    success: false,
  });
  const [address, setAddress] = useState({
    value: "",
    error: "",
    success: false,
  });
  const [extraInfoData, setExtraInfoData] = useState({});
  const [instructions, setInstructions] = useState("");
  const [price, setPrice] = useState(0);
  const [canSubmitBooking, setCanSubmitBooking] = useState(true);
  const [usersData, setUsersData] = useState([]);
  const [customerAddresses, setCustomerAddresses] = useState([]);
  const [isExistingCustomer, setIsExistingCustomer] = useState(false);
  const [autoFillCustomerId, setAutoFillCustomerId] = useState("");
  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const [addressId, setAddressId] = useState("");
  const [editedPrice, setEditedPrice] = useState(0);

  // Functions
  const handleAutoFillCustomerDetail = (id) => {
    const query = `${id}/`;
    const payload = {
      query: query,
    };
    dispatch(getCustomerDetail(payload));
    setIsExistingCustomer(true);
  };

  const getDates = (serviceId, serviceProviderId) => {
    let apiData = {
      type: `${API_SERVICES}${serviceId}/provider/${serviceProviderId}/dates/`,
      apiType: "GET",
    };

    apiCall(apiData)
      ?.then((res) => {
        setDatesRes(res?.dates);
      })
      ?.catch((err) => {
        toast?.error(getErrorMessage(err));
      });
  };

  const getTimeSlots = (selectedDate) => {
    let apiData = {
      type: `${API_SERVICES}${service}/provider/${
        serviceDataRes?.id
      }/slots/?date=${moment(selectedDate)?.format("YYYY-MM-DD")}`,

      apiType: "GET",
    };

    apiCall(apiData)
      ?.then((res) => {
        const formatSlotTiming = (time) => {
          const dateWithTimeString = `${new Date()?.toDateString()} ${time}`;
          return moment(dateWithTimeString)?.format("hh:mm A");
        };
        const processedData = res?.map((dates) => ({
          name: `${formatSlotTiming(dates?.start_time)} - ${formatSlotTiming(
            dates?.end_time
          )}`,
          date: dates?.start_time,
          is_active: dates?.is_active,
        }));

        const filterTimeSlot = processedData?.filter((item) => {
          if (item?.is_active) {
            return item;
          }
        });

        setTimeSlotsRes(filterTimeSlot);
      })
      ?.catch((err) => {
        toast?.error(getErrorMessage(err));
      });
  };

  const getServiceData = (serviceId) => {
    let apiData = {
      type: `${API_SERVICES}${serviceId}/provider-details-by-id/${supplierId}/`,
      apiType: "GET",
    };

    apiCall(apiData)
      ?.then((res) => {
        const processedData = {
          name: res?.provider?.company_name,
          id: res?.id,
          is_active: res?.is_active,
          is_at_station: res?.is_at_station,
          is_extra_field_required: res?.is_extra_field_required,
          is_recurring_enabled: res?.is_recurring_enabled,
          additional_fields: res?.additional_fields,
        };
        setServiceDataRes(processedData);
        getDates(res?.service?.id, res?.id);
      })
      ?.catch((err) => {
        toast?.error(getErrorMessage(err));
      });
  };

  const handleMap = (latAndLng) => {
    setMapCoordinates(latAndLng);
  };

  const onChangeDynamicFields = (field, value) => {
    const fieldValue = {
      id: field?.id,
      field_name: field?.field_name,
      field_type: field?.field_type,
      is_active: field?.is_active,
      is_price_unit_field: field?.is_price_unit_field,
      is_required: field?.is_required,
      price_per_unit: field?.price_per_unit,
      field_value: value,
    };

    extraInfoData[field?.field_name] = fieldValue;

    setReRender(!reRender);

    if (field?.is_price_unit_field) {
      getServicePrice();
    }
  };

  const autoFillFormForPriceUnitFields = () => {
    const additional_fields = serviceDataRes?.additional_fields;
    for (let i = 0; i < additional_fields?.length; i++) {
      if (
        additional_fields[i]?.is_active &&
        additional_fields[i]?.is_price_unit_field &&
        additional_fields[i]?.is_required
      ) {
        switch (additional_fields[i]?.field_type) {
          case SERVICE_FIELD_TYPE?.BOOLEAN:
            onChangeDynamicFields(additional_fields[i], 1);
            break;
          case SERVICE_FIELD_TYPE?.INTEGER:
            onChangeDynamicFields(additional_fields[i], 1);
            break;
          case SERVICE_FIELD_TYPE?.PRICE_COMBINATION:
            let firstKey = Object?.keys(additional_fields[i]?.object_value)[0];
            onChangeDynamicFields(additional_fields[i], firstKey);
            break;
          default:
            break;
        }
      }
    }
  };

  const getServicePrice = () => {
    const payload = {
      quantity: 1,
    };

    if (serviceDataRes?.is_extra_field_required) {
      let additionalFieldsData = Object?.entries(extraInfoData)
        ?.filter((item) => item[1]?.is_price_unit_field)
        ?.map(([key, value]) =>
          JSON?.stringify({
            service_field: value?.id,
            value: value?.field_value,
          })
        );
      payload.additional_fields = additionalFieldsData;
    }

    const apiData = {
      type: `${API_SERVICES}${service}/provider/${serviceDataRes?.id}/get_price/`,
      payload: payload,
      apiType: "POST",
      isFile: true,
    };

    apiCall(apiData)
      ?.then((res) => {
        setPrice(res);
      })
      .catch((error) => {
        toast?.error(getErrorMessage(error));
      });
  };

  const dynamicFieldsValidation = () => {
    let additional_fields = serviceDataRes?.additional_fields;

    let filledData = JSON?.parse(JSON?.stringify(extraInfoData));

    for (let i = 0; i < additional_fields?.length; i++) {
      if (
        additional_fields[i]?.is_active &&
        additional_fields[i]?.is_price_unit_field
      ) {
        if (
          additional_fields[i]?.field_name ===
          filledData[additional_fields[i]?.field_name]?.backendFieldName
        ) {
          switch (filledData[additional_fields[i]?.field_name]?.field_type) {
            case SERVICE_FIELD_TYPE?.INTEGER:
              if (
                filledData[additional_fields[i]?.field_name]?.inputValue < 1
              ) {
                delete filledData[additional_fields[i]?.field_name];
              }
              break;
          }
        }
      }
    }

    if (
      Object?.keys(filledData)?.length < serviceDataRes?.minimum_required_fields
    ) {
      return false;
    } else {
      for (let i = 0; i < additional_fields?.length; i++) {
        if (
          additional_fields[i]?.is_required &&
          additional_fields[i]?.is_active
        ) {
          if (
            additional_fields[i]?.field_name !=
            extraInfoData[additional_fields[i]?.field_name]?.field_name
          ) {
            return false;
          } else {
            if (
              extraInfoData[additional_fields[i]?.field_name]?.field_type ==
                SERVICE_FIELD_TYPE?.INTEGER &&
              extraInfoData[additional_fields[i]?.field_name]?.field_value == 0
            ) {
              return false;
            } else if (
              (extraInfoData[additional_fields[i]?.field_name]?.field_type ==
                SERVICE_FIELD_TYPE?.TEXT ||
                extraInfoData[additional_fields[i]?.field_name]?.field_type ==
                  SERVICE_FIELD_TYPE?.CHARACTER) &&
              (extraInfoData[
                additional_fields[i]?.field_name
              ]?.field_value?.trim() == "" ||
                extraInfoData[
                  additional_fields[i]?.field_name
                ]?.field_value?.trim() == " ")
            ) {
              return false;
            }
          }
        }
      }
      return true;
    }
  };

  const resetInputs = (type) => {
    switch (type) {
      case "service":
        setServiceDataRes([]);
        setDatesRes([]);
        setDate("");
        setTimeSlotsRes([]);
        setTimeSlot("");
        setMapAddress("");
        setMapCoordinates({
          lat: 6.927079,
          lng: 79.861244,
        });
        setVillaOrApartment({
          value: "",
          error: "",
          success: false,
        });
        setBuildingOrStreet({
          value: "",
          error: "",
          success: false,
        });
        setAddress({
          value: "",
          error: "",
          success: false,
        });
        setExtraInfoData({});
        setInstructions("");
        setPrice("");
        break;

      case "date":
        setTimeSlotsRes([]);
        setTimeSlot("");

        break;

      case "address":
        setVillaOrApartment({ error: "", success: false, value: "" });
        setBuildingOrStreet({ error: "", success: false, value: "" });
        setAddress({ error: "", success: false, value: "" });
        setAddressId("");
        break;

      default:
        setExtraInfoData({});
        break;
    }
  };

  const createBookingFormHandler = (event) => {
    event?.preventDefault();

    const isDynamicFieldsValidated = dynamicFieldsValidation();
    if (!isDynamicFieldsValidated) return;

    const address_line = {
      villa: villaOrApartment?.value,
      street: buildingOrStreet?.value,
      address: address?.value,
      full_address: mapAddress,
    };

    const payload = {
      instruction: instructions,
      slot: `${date}T${timeSlot}.00+05:30`,
      quantity: 1,
      is_cash_on_delivery: true,
      user: JSON?.stringify({
        name: name?.value,
        email: email?.value,
        phone: number?.value,
      }),
      location: JSON?.stringify({
        latitude: mapCoordinates?.lat,
        longitude: mapCoordinates?.lng,
        address_line: JSON?.stringify(address_line),
      }),
      custom_price: editedPrice,
    };

    if (String(price?.total) === String(editedPrice)) {
      delete payload?.custom_price;
    }

    if (serviceDataRes?.is_at_station) {
      delete payload?.location;
    }

    if (instructions === "") {
      delete payload.instruction;
    }

    if (email.value === "") {
      payload.user = JSON?.stringify({
        name: name?.value,
        phone: number?.value,
      });
    }

    if (serviceDataRes?.is_extra_field_required) {
      let data = Object?.entries(extraInfoData)?.map((item) => {
        let inputLabel = item[1].field_name;
        let inputType = item[1].field_type;
        let inputId = item[1].id;

        switch (inputType) {
          case SERVICE_FIELD_TYPE?.BOOLEAN:
            return {
              field_name: inputLabel,
              field_value: item[1]?.field_value,
              field_type: inputType,
              id: inputId,
            };
          case SERVICE_FIELD_TYPE?.CHARACTER:
            return {
              field_name: inputLabel,
              field_value: item[1]?.field_value + "",
              field_type: inputType,
              id: inputId,
            };
          case SERVICE_FIELD_TYPE?.DROPDOWN:
            return {
              field_name: inputLabel,
              field_value: item[1]?.field_value + "",
              field_type: inputType,
              id: inputId,
            };
          case SERVICE_FIELD_TYPE?.INTEGER:
            return {
              field_name: inputLabel,
              field_value: item[1]?.field_value + "",
              field_type: inputType,
              id: inputId,
            };
          case SERVICE_FIELD_TYPE?.OPTIONS:
            return {
              field_name: inputLabel,
              field_value: item[1]?.field_value + "",
              field_type: inputType,
              id: inputId,
            };
          case SERVICE_FIELD_TYPE?.PRICE_COMBINATION:
            return {
              field_name: inputLabel,
              field_value: item[1]?.field_value + "",
              field_type: inputType,
              id: inputId,
            };
          case SERVICE_FIELD_TYPE?.TEXT:
            return {
              field_name: inputLabel,
              field_value: item[1]?.field_value + "",
              field_type: inputType,
              id: inputId,
            };
        }
      });

      let filteredExtraInfo = [];
      for (let i = 0; i < data?.length; i++) {
        const element = data[i];

        const fieldData = JSON?.stringify({
          service_field: element?.id,
          value: element?.field_value,
        });

        switch (element?.field_type) {
          case SERVICE_FIELD_TYPE?.CHARACTER:
            if (element?.field_value?.trim() != "")
              filteredExtraInfo?.push(fieldData);
            break;
          case SERVICE_FIELD_TYPE?.DROPDOWN:
            if (element?.field_value?.trim() != "")
              filteredExtraInfo?.push(fieldData);
            break;
          case SERVICE_FIELD_TYPE?.INTEGER:
            if (element?.field_value > 0) filteredExtraInfo?.push(fieldData);
            break;
          case SERVICE_FIELD_TYPE?.OPTIONS:
            if (element?.field_value?.trim() != "")
              filteredExtraInfo?.push(fieldData);
            break;
          case SERVICE_FIELD_TYPE?.PRICE_COMBINATION:
            if (element?.field_value?.trim() != "")
              filteredExtraInfo?.push(fieldData);
            break;
          case SERVICE_FIELD_TYPE?.TEXT:
            if (element?.field_value?.trim() != "")
              filteredExtraInfo?.push(fieldData);
            break;
          case SERVICE_FIELD_TYPE?.BOOLEAN:
            filteredExtraInfo?.push(fieldData);
            break;
        }
      }
      payload.additional_fields = filteredExtraInfo;
    }

    setLoading(true);

    const apiData = {
      type: `${API_SERVICES}${service}/provider/${serviceDataRes?.id}/add-request/`,
      payload: payload,
      apiType: "PATCH",
      isRaw: true,
    };

    if (canSubmitBooking) {
      apiCall(apiData)
        ?.then((res) => {
          navigate(-1);
          toast.success("Booking created successfully!");
        })
        ?.catch((err) => {
          toast?.error(getErrorMessage(err));
        })
        ?.finally(() => {
          setLoading(false);
        });
    }

    setLoading(false);
  };

  const handleAutoFillUserDetail = async (number) => {
    const apiData = {
      type: `${API_CUSTOMER_VALIDATION}?query=${number}`,
      apiType: "GET",
    };
    if (number.length >= 3) {
      try {
        const response = await apiCall(apiData);
        if (response?.length > 0) {
          setUsersData(response);
        } else {
          setUsersData([]);
        }
      } catch (error) {
        toast.error(getErrorMessage(error));
      } finally {
      }
    } else {
      setUsersData([]);
      return;
    }
  };

  const handleSuggestionBoxRowClick = (data) => {
    if (data) {
      customerId = data?.id;
      setAutoFillCustomerId(data?.id);
      setName({ ...name, value: data?.name });
      setEmail({ ...email, value: data?.email });
      setNumber({ ...number, value: data?.phone });
      setUsersData([]);
      setIsExistingCustomer(true);
      handelAutoFillUserAddress(customerId);
    } else {
      setIsExistingCustomer(false);
    }
  };

  const handelAutoFillUserAddress = async (id) => {
    const apiData = {
      type: `${API_USERS}${id}/address/`,
      apiType: "GET",
    };

    try {
      const response = await apiCall(apiData);
      setCustomerAddresses(response);
    } catch (error) {
      toast.error(getErrorMessage(error));
    }
  };

  const handelAddNewAddress = () => {
    setIsAddNewAddress(!isAddNewAddress);
    setIsExistingCustomer(false);
    setCustomerAddresses([]);
  };

  const handelBackToSaveAddress = () => {
    setIsAddNewAddress(!isAddNewAddress);
    handelAutoFillUserAddress(autoFillCustomerId);
    setIsExistingCustomer(true);
    setMapAddress("");
    setAddress({ error: "", success: false, value: "" });
    setBuildingOrStreet({ error: "", success: false, value: "" });
    setVillaOrApartment({ error: "", success: false, value: "" });
    setAddressId("");
  };

  useEffect(() => {
    if (addressId && customerAddresses) {
      for (let i = 0; i < customerAddresses.length; i++) {
        const element = customerAddresses[i];
        if (addressId === element.id) {
          setMapAddress(element?.address_line?.full_address);
          setAddress({ ...address, value: element?.address_line?.address });
          setBuildingOrStreet({
            ...buildingOrStreet,
            value: element?.address_line?.street,
          });
          setVillaOrApartment({
            ...villaOrApartment,
            value: element?.address_line?.villa,
          });
        }
      }
    }
  }, [addressId, customerAddresses]);

  useEffect(() => {
    dispatch(getSupplierProfile());
  }, []);

  useEffect(() => {
    if (customerId) {
      handleAutoFillCustomerDetail(customerId);
      handelAutoFillUserAddress(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (customerAddresses && customerId) {
      let singleAddress = customerAddresses[0];
      setAddress({
        ...address,
        value: singleAddress?.address_line?.full_address,
      });
      setVillaOrApartment({
        ...villaOrApartment,
        value: singleAddress?.address_line?.villa,
      });
      setBuildingOrStreet({
        ...buildingOrStreet,
        value: singleAddress?.address_line?.street,
      });
    }

    return () => {
      setAddress({
        ...address,
        value: "",
      });
      setVillaOrApartment({
        ...villaOrApartment,
        value: "",
      });
      setBuildingOrStreet({
        ...buildingOrStreet,
        value: "",
      });
    };
  }, [customerAddresses, customerId, timeSlot, date]);

  useEffect(() => {
    if (customerDetailRes) {
      setEmail({ ...email, value: customerDetailRes?.customer_details?.email });
      setNumber({
        ...number,
        value: customerDetailRes?.customer_details?.phone,
      });
      setName({ ...name, value: customerDetailRes?.customer_details?.name });
      setName({ ...name, value: customerDetailRes?.customer_details?.name });
    }

    return () => {
      setEmail({ value: "", error: "", success: "" });
      setNumber({ value: "", error: "", success: "" });
      setName({ value: "", error: "", success: "" });
    };
  }, [customerDetailRes]);

  useEffect(() => {
    if (!customerId) {
      setEmail({ value: "", error: "", success: "" });
      setNumber({ value: "", error: "", success: "" });
      setName({ value: "", error: "", success: "" });
      setAddress({
        ...address,
        value: "",
      });
      setVillaOrApartment({
        ...villaOrApartment,
        value: "",
      });
      setBuildingOrStreet({
        ...buildingOrStreet,
        value: "",
      });
    }
  }, [customerId]);

  useEffect(() => {
    if (service && serviceDataRes) {
      if (serviceDataRes?.is_extra_field_required) {
        autoFillFormForPriceUnitFields();
      } else {
        if (serviceDataRes?.id) {
          getServicePrice();
        }
      }
    }
  }, [service, serviceDataRes]);

  useEffect(() => {
    if (name?.value && number?.value && service && timeSlot && date) {
      if (serviceDataRes?.is_extra_field_required) {
        const isDynamicFieldsValidated = dynamicFieldsValidation();
        if (isDynamicFieldsValidated) {
          setCanSubmitBooking(true);
        } else {
          setCanSubmitBooking(false);
        }
      } else {
        setCanSubmitBooking(true);
      }

      if (!serviceDataRes?.is_at_station) {
        if (
          !(
            villaOrApartment?.value &&
            buildingOrStreet?.value &&
            address?.value
          )
        ) {
          setCanSubmitBooking(false);
        } else {
          setCanSubmitBooking(true);
        }
      }
    } else {
      setCanSubmitBooking(false);
    }
  }, [
    name,
    number,
    service,
    extraInfoData,
    reRender,
    villaOrApartment,
    buildingOrStreet,
    address,
    serviceDataRes,
    date,
    instructions,
    timeSlot,
    customerId,
    customDateAndTimeSlot,
    useCustomDateAndTimeSlot,
  ]);

  useEffect(() => {
    if (customerId) {
      setAutoFillCustomerId(customerId);
    }
  }, [customerId]);

  useEffect(() => {
    if (price || price?.total === 0) {
      setEditedPrice(price?.total);
    }
  }, [price, price?.total]);

  return (
    <div className="createBooking">
      <div className="header">
        <Header Heading={t("create_booking")} isBack={true} />
      </div>

      <form className="form" onSubmit={createBookingFormHandler}>
        {/* Customer Details Section */}
        <div className="section">
          <h2 className="heading3">{t("customer_details")}</h2>

          <div className="halfWidth">
            {/* Number */}
            <div className="inputAndSuggestionBox">
              <Input
                isRequired
                label={t("phone_number")}
                placeholder="0000-00000"
                value={number?.value}
                onChange={(e) => {
                  if (e?.target?.value?.length === 10) return;

                  setNumber((previous) => ({
                    ...previous,
                    value: e?.target?.value,
                  }));

                  handleAutoFillUserDetail(e.target.value);

                  setName({ ...name, value: "" });
                  setEmail({ ...email, value: "" });
                  setIsExistingCustomer(false);
                }}
                start={true}
                startChildren={<NumberBox />}
                type="number"
                maxLength={9}
                letterSpacing={true}
                error={number?.error}
              />
              {usersData?.length > 0 && (
                <ul className="suggestionBox">
                  {usersData.map((item, index) => (
                    <li
                      key={index}
                      onClick={() => handleSuggestionBoxRowClick(item)}
                    >{`${item?.name} (${item?.phone})`}</li>
                  ))}
                </ul>
              )}
            </div>

            {/* Email Address */}
            <Input
              type="email"
              label={t("email_address")}
              placeholder={t("email_address_placeholder")}
              value={email?.value}
              onChange={(e) => {
                setEmail((previous) => ({
                  ...previous,
                  value: e?.target?.value,
                }));
              }}
              disabled={isExistingCustomer}
            />

            {/* Name */}
            <Input
              isRequired
              label={t("name")}
              placeholder={t("name_placeholder")}
              value={name?.value}
              onChange={(e) => {
                setName((previous) => ({
                  ...previous,
                  value: e?.target?.value,
                }));
              }}
              onBlur={() => {
                const nameValidation = isValidation(name?.value, "name");
                setName((previous) => ({
                  ...previous,
                  error: nameValidation?.error,
                  success: nameValidation?.success,
                }));
              }}
              error={name?.error}
              disabled={isExistingCustomer}
            />
          </div>
        </div>

        {/* Service Details Section */}
        <div className="section">
          <h2 className="heading3">{t("service_details")}</h2>

          <div className="fullWidth">
            {/* Service */}
            <CustomDropDown
              isRequired
              label={t("service")}
              placeholder={t("select_service")}
              dropDownData={servicesRes || ["No data available!"]}
              valueKey={"service_id"}
              displayKey={"service_name"}
              selectedValue={service}
              setSelectedValue={(value) => {
                setService(value);
                getServiceData(value);
                resetInputs("service");
              }}
              disabled={servicesRes ? false : true}
            />

            {!useCustomDateAndTimeSlot ? (
              <div className="halfWidth">
                {/* Date */}
                <CustomDropDown
                  isRequired
                  label={t("date")}
                  placeholder={t("select_date")}
                  dropDownData={datesRes || ["No data available!"]}
                  valueKey={"date"}
                  displayKey={"date"}
                  selectedValue={date}
                  setSelectedValue={(value) => {
                    setDate(value);
                    getTimeSlots(value);
                    resetInputs("date");
                  }}
                  disabled={service && datesRes?.length > 0 ? false : true}
                />

                {/* Time Slots */}
                <CustomDropDown
                  isRequired
                  label={t("time_slots")}
                  placeholder={t("select_time_slot")}
                  dropDownData={timeSlotsRes || ["No data available!"]}
                  valueKey={"date"}
                  displayKey={"name"}
                  selectedValue={timeSlot}
                  setSelectedValue={(e) => {
                    setTimeSlot(e);
                  }}
                  disabled={date && timeSlotsRes?.length > 0 ? false : true}
                />
              </div>
            ) : (
              <div className="halfWidth">
                <Input
                  type={"datetime-local"}
                  label={t("custom_date_and_time")}
                  value={customDateAndTimeSlot}
                  onChange={(e) => {
                    const selectedDateTime = e?.target?.value;
                    const currentDateTime = moment().format("YYYY-MM-DDTHH:mm");

                    // Check if the selected date is in the past
                    if (selectedDateTime < currentDateTime) {
                      setDate("");
                      setTimeSlot("");
                      setCustomDateAndTimeSlot("");
                      alert("Please select a future date and time.");
                      return;
                    }

                    // Update state if the selected date is valid
                    setCustomDateAndTimeSlot(selectedDateTime);
                    setDate(moment(selectedDateTime).format("YYYY-MM-DD"));
                    setTimeSlot(moment(selectedDateTime).format("HH:mm:00"));
                  }}
                  inputProps={{
                    min: moment().format("YYYY-MM-DDTHH:mm"),
                    step: "any",
                  }}
                />
              </div>
            )}

            <CheckBox
              disabled={service && datesRes?.length > 0 ? false : true}
              label={t("use_custom_date_and_time_slot")}
              value={useCustomDateAndTimeSlot}
              onChange={() => {
                setUseCustomDateAndTimeSlot(!useCustomDateAndTimeSlot);
                setDate("");
                setTimeSlot("");
                resetInputs("address");
                setCustomDateAndTimeSlot("");
              }}
            />
          </div>
        </div>

        {/* Address Details Section */}
        {date && !serviceDataRes?.is_at_station ? (
          <div className="section">
            <h2 className="heading3">{t("address_details")}</h2>
            {customerAddresses?.length > 0 && isExistingCustomer ? (
              <>
                <div className="addressDropdownAndAddNewAddressButton">
                  <CustomDropDown
                    isRequired
                    label={t("saved_addresses")}
                    placeholder={t("saved_addresses_placeholder")}
                    dropDownData={customerAddresses || ["No data available!"]}
                    valueKey={"id"}
                    displayKey={"address_line"}
                    displayCustomListItem
                    customListItem={(item) => {
                      const addressObj = item?.address_line;
                      return `${addressObj?.villa && addressObj?.villa + ", "}${
                        addressObj?.street && addressObj?.street + ", "
                      }${addressObj?.full_address}`;
                    }}
                    selectedValue={mapAddress}
                    setSelectedValue={(value) => {
                      console.log(value);
                      setAddressId(value);
                    }}
                    disabled={servicesRes ? false : true}
                    isValueSet
                  />
                  <SecondaryButton
                    title={t("add_new_address")}
                    onClick={handelAddNewAddress}
                  />
                </div>
              </>
            ) : (
              <div class="fullWidth">
                {isAddNewAddress && (
                  <BackToSaveAddress
                    title={t("back_to_save_address")}
                    onClick={handelBackToSaveAddress}
                  />
                )}
                {/* Map */}
                <GoogleMap
                  updateLocation={handleMap}
                  setFullAddress={setMapAddress}
                  fullAddress={mapAddress}
                />

                <div className="fullWidth">
                  {/* Address */}
                  <TextArea
                    isRequired
                    label={t("address")}
                    placeholder={t("address_placeholder")}
                    value={address?.value}
                    onChange={(e) => {
                      setAddress((previous) => ({
                        ...previous,
                        value: e?.target?.value,
                      }));
                    }}
                    onBlur={() => {
                      const addressValidation = isValidation(
                        address?.value,
                        "address"
                      );
                      setAddress((error) => ({
                        ...error,
                        error: addressValidation?.error,
                        success: addressValidation?.success,
                      }));
                    }}
                    error={address?.error}
                    maxLength={250}
                  />
                </div>

                <div className="halfWidth">
                  {/* Villa or Apartment */}
                  <Input
                    isRequired
                    label={t("villa_or_apartment")}
                    labelStyle={{ textTransform: "none" }}
                    placeholder={t("villa_or_apartment_placeholder")}
                    value={villaOrApartment?.value}
                    onChange={(e) => {
                      setVillaOrApartment((previous) => ({
                        ...previous,
                        value: e?.target?.value,
                      }));
                    }}
                    onBlur={() => {
                      const villaOrApartmentValidation = isValidation(
                        villaOrApartment?.value,
                        "villaOrApartment"
                      );
                      setVillaOrApartment((error) => ({
                        ...error,
                        error: villaOrApartmentValidation?.error,
                        success: villaOrApartmentValidation?.success,
                      }));
                    }}
                    error={villaOrApartment?.error}
                  />

                  {/* Building or Street */}
                  <Input
                    isRequired
                    label={t("building_or_street")}
                    labelStyle={{ textTransform: "none" }}
                    placeholder={t("building_or_street_placeholder")}
                    value={buildingOrStreet?.value}
                    onChange={(e) => {
                      setBuildingOrStreet((previous) => ({
                        ...previous,
                        value: e?.target?.value,
                      }));
                    }}
                    onBlur={() => {
                      const buildingOrStreetValidation = isValidation(
                        buildingOrStreet?.value,
                        "buildingOrStreet"
                      );
                      setBuildingOrStreet((error) => ({
                        ...error,
                        error: buildingOrStreetValidation?.error,
                        success: buildingOrStreetValidation?.success,
                      }));
                    }}
                    error={buildingOrStreet?.error}
                  />
                </div>
              </div>
            )}
          </div>
        ) : null}

        {/* Extra Information Section */}
        {date &&
        timeSlot &&
        serviceDataRes?.is_extra_field_required &&
        serviceDataRes?.additional_fields?.length > 0 ? (
          <div className="section">
            <h2 className="heading3">{t("extra_information")}</h2>

            {/* Dynamic Fields */}
            <div className="fullWidth dynamicFieldsContainer">
              {serviceDataRes?.additional_fields
                ?.slice()
                ?.sort((a, b) => a?.order - b?.order)
                ?.map((item, index) => {
                  return (
                    <DynamicFields
                      key={index}
                      data={item}
                      extraInfoData={extraInfoData}
                      onChangeDynamicFields={onChangeDynamicFields}
                    />
                  );
                })}
            </div>
          </div>
        ) : null}

        {/* Instructions Section */}
        {timeSlot ? (
          <div className="section">
            <h2 className="heading3">{t("instructions")}</h2>

            <div className="fullWidth">
              {/* Instructions */}
              <TextArea
                label={t("instructions")}
                placeholder={t("instructions_placeholder")}
                value={instructions}
                onChange={(e) => {
                  setInstructions(e?.target?.value);
                }}
                maxLength={250}
              />
            </div>
          </div>
        ) : null}

        {/* Price Section */}
        {timeSlot && service && (
          <div className="bottomBar">
            <div className="priceContainer">
              <p className="price heading2">
                LKR{" "}
                {String(price?.total) === String(editedPrice)
                  ? price?.total
                  : editedPrice || 0}
              </p>
              <p className="priceDescription bodyText ">Total included VAT</p>
              <p
                className="priceDescription bodyText"
                style={{
                  opacity: 1,
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setEnableEditPrice(true);
                }}
              >
                Edit Price
              </p>
            </div>

            <SecondaryButton
              title={t("book_now")}
              disabled={!canSubmitBooking}
              isLoading={loading}
              buttonClassName="bottomBarButton"
            />
          </div>
        )}
      </form>
      <EditPriceModal
        isOpen={enableEditPrice}
        onClose={() => setEnableEditPrice(false)}
        setEditedPrice={setEditedPrice}
        editedPrice={editedPrice}
      />
    </div>
  );
};

const NumberBox = () => {
  return (
    <div className="numberBox">
      <div className="number">
        <p>+94</p>
      </div>
      <div className="divider"></div>
    </div>
  );
};

const BackToSaveAddress = ({ title, onClick }) => {
  return (
    <div className="BackToSaveAddress" role="button" onClick={onClick}>
      <img src={Icons.BACK_ICON_BLUE} alt="back icon" />
      <p className="bodyText">{title}</p>
    </div>
  );
};
