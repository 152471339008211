import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const isUserLoggedIn = useSelector((state) => state.auth.isUserLoggedIn);

  return isUserLoggedIn ? <Outlet /> : <Navigate to="/signin" />;
};

export default ProtectedRoutes;
