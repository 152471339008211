import { initializeApp } from "firebase/app";

//stage
// const firebaseConfig = {
//   apiKey: "AIzaSyBML4wRLSBAtaoe8TkDl87k2cHSxdSK14U",
//   authDomain: "mrgg-app-stage.firebaseapp.com",
//   projectId: "mrgg-app-stage",
//   storageBucket: "mrgg-app-stage.appspot.com",
//   messagingSenderId: "204542714885",
//   appId: "1:204542714885:web:dd7d1a436ed10a5e38686c",
//   measurementId: "G-9HLBYLWSBF",
// };

//prod
const firebaseConfig = {
  apiKey: "AIzaSyAvjKwFLs8q7GiEZvqRoxbucvVtSBy_7UI",
  authDomain: "mrgg-app.firebaseapp.com",
  projectId: "mrgg-app",
  storageBucket: "mrgg-app.appspot.com",
  messagingSenderId: "574479567241",
  appId: "1:574479567241:web:3c8dcd3cdf5b5ea2a83958",
  measurementId: "G-TL1YDDLRBR",
};

export const app = initializeApp(firebaseConfig);
