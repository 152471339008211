import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { FAQ } from "redux/services/apiTypes";

const initialState = {
  FAQLoading: false,
  FAQSuccess: false,
  FAQRes: null,
  FAQErr: null,
};

export const getFAQs = createAsyncThunk(
  "faq/list",
  async (data, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: FAQ,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const FAQSlice = createSlice({
  name: "FAQ",
  initialState,
  reducers: {
    clearFAQ: (state, action) => {
      state.FAQLoading = false;
      state.FAQSuccess = false;
      state.FAQRes = null;
      state.FAQErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFAQs.pending, (state, action) => {
        state.FAQLoading = true;
        state.FAQSuccess = false;
      })
      .addCase(getFAQs.fulfilled, (state, action) => {
        state.FAQLoading = false;
        state.FAQSuccess = true;
        state.FAQRes = action.payload;
      })
      .addCase(getFAQs.rejected, (state, action) => {
        state.FAQLoading = false;
        state.FAQSuccess = true;
        state.FAQErr = action.payload;
      });
  },
});

export const { clearFAQ } = FAQSlice.actions;

export default FAQSlice.reducer;
