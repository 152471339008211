import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { API_SERVICE_PROVIDER_DASHBOARD } from "redux/services/apiTypes";

const dashboardInitialData = {
  booking: {
    total: 0,
    pending: 0,
    ongoing: 0,
    completed: 0,
  },
  earning: {
    total: 0,
    withdrawable_balance: 0,
  },
  completed_analytic_data: {
    by: "this_month",
    data: [
      {
        xAxis: 0,
        yAxis: 0,
        label: "",
      },
      {
        xAxis: 0,
        yAxis: 0,
        label: "",
      },
      {
        xAxis: 0,
        yAxis: 0,
        label: "",
      },
      {
        xAxis: 0,
        yAxis: 0,
        label: "",
      },
    ],
  },
  earning_analytic_data: {
    by: "this_month",
    data: [
      {
        xAxis: 0,
        yAxis: 0,
        label: "",
      },
      {
        xAxis: 0,
        yAxis: 0,
        label: "",
      },
      {
        xAxis: 0,
        yAxis: 0,
        label: "",
      },
      {
        xAxis: 0,
        yAxis: 0,
        label: "",
      },
    ],
  },
};

const initialState = {
  dashboardLoading: false,
  dashboardSuccess: false,
  dashboardRes: dashboardInitialData,
  dashboardErr: null,
};

export const getDashboard = createAsyncThunk(
  "dashboard/data",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${API_SERVICE_PROVIDER_DASHBOARD}${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const dashboardSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {
    clearDashboard: (state, action) => {
      state.dashboardLoading = false;
      state.dashboardSuccess = false;
      state.dashboardRes = dashboardInitialData;
      state.dashboardErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashboard.pending, (state, action) => {
        state.dashboardLoading = true;
        state.dashboardSuccess = false;
      })
      .addCase(getDashboard.fulfilled, (state, action) => {
        state.dashboardLoading = false;
        state.dashboardSuccess = true;
        state.dashboardRes = action.payload;
      })
      .addCase(getDashboard.rejected, (state, action) => {
        state.dashboardLoading = false;
        state.dashboardSuccess = true;
        state.dashboardErr = action.payload;
      });
  },
});

export const { clearDashboard } = dashboardSlice.actions;

export default dashboardSlice.reducer;
