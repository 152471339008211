import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import apiCall from "redux/services/apiCall";
import { NEW_BOOKING } from "redux/services/apiTypes";
// import { recurringDates } from "redux/services/apiTypes";

const initialState = {
  recurringDatesLoading: false,
  recurringDatesSuccess: false,
  recurringDatesRes: null,
  recurringDatesErr: null,
};

export const getRecurringDates = createAsyncThunk(
  "recurringDates/list",
  async (payload, { fulfillWithValue, rejectWithValue }) => {
    const apiData = {
      type: `${NEW_BOOKING}${payload.query}`,
      apiType: "GET",
    };
    try {
      let res = await apiCall(apiData);
      return fulfillWithValue(res);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const recurringDatesSlice = createSlice({
  name: "recurringDates",
  initialState,
  reducers: {
    clearRecurringDates: (state, action) => {
      state.recurringDatesLoading = false;
      state.recurringDatesSuccess = false;
      state.recurringDatesRes = null;
      state.recurringDatesErr = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRecurringDates.pending, (state, action) => {
        state.recurringDatesLoading = true;
        state.recurringDatesSuccess = false;
      })
      .addCase(getRecurringDates.fulfilled, (state, action) => {
        state.recurringDatesLoading = false;
        state.recurringDatesSuccess = true;
        state.recurringDatesRes = action.payload;
      })
      .addCase(getRecurringDates.rejected, (state, action) => {
        state.recurringDatesLoading = false;
        state.recurringDatesSuccess = false;
        state.recurringDatesErr = action.payload;
      });
  },
});

export const { clearRecurringDates } = recurringDatesSlice.actions;

export default recurringDatesSlice.reducer;
